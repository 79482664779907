import AddToCart from 'Component/AddToCart';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Icon from 'Component/Icon';
import Image from 'Component/Image';
import Link from 'Component/Link';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { PRODUCT_ATTRIBUTE_SET_NAME } from 'Component/ProductAttributeValue/ProductAttributeValue.config';
import ProductLabel from 'Component/ProductLabel';
import ProductPrice from 'Component/ProductPrice';
import ProductReviewRating from 'Component/ProductReviewRating';
import Tooltip from 'Component/Tooltip';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import { GRID_LAYOUT } from 'SourceRoute/CategoryPage/CategoryPage.config';
import { isCrawler } from 'Util/Browser';
import { formatDate } from 'Util/Date';
import { eventPersist } from 'Util/Element';
import { formatPrice } from 'Util/Price';
import {
    getCodazonLabelByType,
    getCodazonLabelWithDiscount,
    getProductIdentificationAttribs,
    getProductLabelType,
} from 'Util/Product/Product';

import './ProductCard.override.style';

/** @namespace SwiatKsiazkiBasic/Component/ProductCard/Component */
export class ProductCard extends SourceProductCard {
    contentObject = {
        ...this.contentObject,
        content: {
            ...this.contentObject.content,
            authorDetails: this.renderAuthor.bind(this),
            productLabels: this.renderProductLabels.bind(this),
            availabilityDetails: this.renderShippingTimeInfo.bind(this),
        },
    };

    renderName(header = true, dynamic = false) {
        const {
            linkTo,
            product: { url },
        } = this.props;

        if (!url) {
            return super.renderName(header, dynamic);
        }

        return (
            <Link block="ProductCard" elem="Link" to={linkTo} onClick={this.registerSharedElement}>
                {super.renderName(header, dynamic)}
            </Link>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const {
            linkTo,
            product: { url },
        } = this.props;

        if (!url || isCrawler()) {
            return (
                <div block="ProductCard" elem="Link">
                    {children}
                </div>
            );
        }

        return (
            <Link block="ProductCard" elem="Link" to={linkTo} onClick={this.registerSharedElement} mix={mix}>
                {children}
            </Link>
        );
    }

    renderPicture(mix = {}) {
        const {
            product: { id, name },
            thumbnail,
        } = this.props;

        return (
            <Image
                imageRef={this.imageRef}
                src={thumbnail}
                alt={name}
                width="225"
                height="270"
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture', mix }}
                isPlaceholder={!id}
                lazyLoadingMode="eager"
                fetchPriority="high"
            />
        );
    }

    renderWishlistItemRemove() {
        const { wishlistItemRemoveAction } = this.props;

        return (
            <button
                block="WishlistItem"
                elem="RemoveBtn"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    wishlistItemRemoveAction();
                }}
                aria-label={__('Remove')}
            >
                <Icon name="bin" fill="#000000" />
            </button>
        );
    }

    renderWishlistItemCommentField() {
        const {
            product: {
                wishlist: { description },
            },
            wishlistItemChangeDescriptionAction,
            inStock,
        } = this.props;

        return (
            <Field
                type={FIELD_TYPE.text}
                attr={{
                    id: 'description',
                    name: 'description',
                    placeholder: __('Add a note'),
                    defaultValue: description,
                }}
                events={{
                    onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    },
                    onChange: ({ target: { value } = {} }) => wishlistItemChangeDescriptionAction(value),
                }}
                mix={{ block: 'WishlistItem', elem: 'CommentField' }}
                isDisabled={!inStock}
            />
        );
    }

    renderVisibleOnHover() {
        const { device, isWishlistItem } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <>
                {this.renderConfigurableOptions()}
                <div block="ProductCard" elem="Footer">
                    {this.renderExtraInfo()}
                    {isWishlistItem && this.renderWishlistItemCommentField()}
                    {this.renderAddToCart()}
                    {isWishlistItem && this.renderWishlistItemPrice()}
                </div>
            </>
        );
    }

    renderWishlistItemPrice() {
        const {
            // product: { type_id: baseType } = {},
            getActiveProduct,
            productPrice,
            isWishlistItem = false,
        } = this.props;
        const product = getActiveProduct();
        const {
            type_id: type,
            price_tiers: priceTiers,
            is_starting_price: isStartingPrice = false,
            is_suggested_price: isSuggestedPrice = false,
        } = product;
        const { price_range: priceRange } = getActiveProduct();

        // const notConfigured = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;

        if (!productPrice || !priceRange) {
            return null;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper" mods={{ wishlistItem: true }}>
                <ProductPrice
                    meta
                    price={productPrice}
                    priceType={type}
                    tierPrices={priceTiers}
                    mix={{ block: 'ProductCard', elem: 'Price' }}
                    isStartingPrice={isStartingPrice}
                    isSuggestedPrice={isSuggestedPrice}
                    isWishlistItem={isWishlistItem}
                />
            </div>
        );
    }

    getTypeFormatLabel(product) {
        if (!product) {
            return null;
        }

        const { attribute_set_name, type_label } = product || {};

        if (
            attribute_set_name === PRODUCT_ATTRIBUTE_SET_NAME.TOY ||
            attribute_set_name === PRODUCT_ATTRIBUTE_SET_NAME.ARTPAP
        ) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ExtraInfoItem">
                <span>{type_label?.replaceAll(',', ', ')}</span>
            </div>
        );
    }

    getTypeReleaseDateLabel(product) {
        if (!product || !product?.attributes) {
            return null;
        }

        const { attributes, attribute_set_name } = product;

        if (
            attribute_set_name === PRODUCT_ATTRIBUTE_SET_NAME.TOY ||
            attribute_set_name === PRODUCT_ATTRIBUTE_SET_NAME.ARTPAP
        ) {
            return null;
        }

        if (Array.isArray(attributes)) {
            const attrReleaseDate = attributes?.find(({ attribute_code } = {}) => attribute_code === 'release_date');
            const releasedate = attrReleaseDate?.attribute_value;

            if (!releasedate) {
                return null;
            }

            return (
                <div block="ProductCard" elem="ExtraInfoItem">
                    <span>{__('Release date: ')}</span>
                    <b>{formatDate(releasedate)}</b>
                </div>
            );
        }

        const { release_date: { attribute_value: releaseDate = {} } = {} } = attributes || {};

        if (releaseDate)
            return (
                <div block="ProductCard" elem="ExtraInfoItem">
                    <span>{__('Release date: ')}</span>
                    <b>{formatDate(releaseDate)}</b>
                </div>
            );

        return null;
    }

    getPublisherLabel(product) {
        if (!product || !product?.attributes) {
            return null;
        }

        const { dictionary: { publishers = [] } = {} } = product || {};

        if (!publishers[0]?.name) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ExtraInfoItem">
                <span>{__('Publisher: ')}</span>
                {publishers[0]?.name}
            </div>
        );
    }

    getOtherTypePublishers(product) {
        if (product) {
            const { product_other_edition: productOtherEdition } = product;

            if (productOtherEdition) {
                return (
                    <div
                        block="ProductCard"
                        elem="ExtraInfoItem"
                        mix={{ block: 'ProductCard', elem: 'ExtraInfoItemPublishersIcons' }}
                    >
                        <span>{__('Other publishers: ')}</span>
                        <div block="ExtraInfoItemPublishersIcons" elem="Icons">
                            {productOtherEdition.map(({ type }) => (
                                <Icon key={type} name={type} height="20" width="20" fill="#DD151E" />
                            ))}
                        </div>
                    </div>
                );
            }

            return null;
        }

        return null;
    }

    getEan(product) {
        if (!product) {
            return null;
        }

        const { ean } = product || {};

        if (!ean) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ExtraInfoItem">
                <span>{__('EAN: ')}</span>
                {ean}
            </div>
        );
    }

    renderWishlistComment() {
        const { product: { wishlist: { description = '' } = {} } = {}, device: { isMobile = false } = {} } = this.props;

        return (
            <div block="ProductCard" elem="SharedWishlistComment">
                {isMobile ? (
                    <span block="ProductCard" elem="SharedWishlistCommentLabel">
                        {__('Comment')}
                    </span>
                ) : null}
                <p>{description}</p>
            </div>
        );
    }

    renderWishlistOldPrice() {
        const {
            product: { price_alert } = {},
            showWishlistTooltip,
            setShowWishlistTooltip,
            device: { isMobile, isTablet } = {},
        } = this.props;
        const { price: priceAlert = '' } = price_alert || {};

        if (!priceAlert) {
            return null;
        }

        return (
            <div block="ProductCard" elem="WishlistOldPriceWrapper">
                <Tooltip
                    placement="top"
                    tooltipText={
                        <div block="ProductCard" elem="WishlistOldPriceTooltipText">
                            <span>{__('Price at time of addition to wish list:')} </span>
                            <span block="ProductCard" elem="WishlistOldPriceTooltipPrice">
                                {formatPrice(priceAlert)}
                            </span>
                        </div>
                    }
                    {...(isMobile || isTablet ? { open: showWishlistTooltip } : {})}
                    styles={{
                        fontSize: '11px',
                        lineHeight: '14px',
                        fontWeight: '400',
                        letterSpacing: '.2px',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
                        borderRadius: '9px',
                        padding: '15px 17px',
                        maxWidth: 197,
                        fontFamily: 'Poppins',
                    }}
                >
                    <button
                        type="button"
                        block="ProductCard"
                        elem="WishlistOldPrice"
                        onClick={eventPersist(setShowWishlistTooltip)}
                    >
                        <span block="ProductCard" elem="WishlistOldPriceInfo">
                            {__('Price down')}
                        </span>
                        <Icon name="info_2" fill="#ffffff" width="12" height="12" />
                    </button>
                </Tooltip>
            </div>
        );
    }

    renderExtraInfo() {
        const { product } = this.props || {};

        const format = this.getTypeFormatLabel(product);
        const releaseDate = this.getTypeReleaseDateLabel(product);

        if (format === null && releaseDate === null) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ExtraInfo">
                {format}
                {releaseDate}
            </div>
        );
    }

    renderExtraInfoList() {
        const { product } = this.props || {};

        return (
            <div block="ProductCard" elem="ExtraInfo">
                {this.getPublisherLabel(product)}
                {this.getTypeReleaseDateLabel(product)}
                {this.getTypeFormatLabel(product)}
                {this.getEan(product)}
                {this.renderShortDescription()}
                {this.getOtherTypePublishers(product)}
            </div>
        );
    }

    renderAddToCart() {
        const { layout } = this.props;

        return this.renderAddToCartButton(layout);
    }

    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            quantity,
            getActiveProduct,
            isWishlistItem,
            wishlistItemRemoveAction,
            inStock,
            device: { isMobile } = {},
        } = this.props;

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                isDisabled={!inStock}
                isIconEnabled={layout === LIST_LAYOUT || isWishlistItem}
                layout={layout}
                quantity={quantity}
                product={getActiveProduct()}
                isWishlistItem={isWishlistItem}
                wishlistItemRemoveAction={wishlistItemRemoveAction}
                shortText={isWishlistItem}
                size={isMobile ? 'extraSmall' : 'medium'}
                variant={isMobile ? 'outline' : 'primary'}
            />
        );
    }

    renderBestsellerLabel() {
        const { product, isBestsellersPage, itemPosition, showBestsellersCounter } = this.props;

        if (!product || !product?.attributes) {
            return null;
        }

        const { codazon_labels = [] } = product;

        const bestsellerLabel = codazon_labels?.filter((label) => label?.custom_class === 'bestseller');
        const isBestsellerCounter = isBestsellersPage && itemPosition && showBestsellersCounter;

        if (bestsellerLabel && bestsellerLabel.length && isBestsellerCounter) {
            const [{ label_link } = {}] = bestsellerLabel;
            const isSecondary = itemPosition > 3;

            return (
                <ProductLabel
                    type="top_with_number"
                    topNumber={itemPosition}
                    labelLink={label_link}
                    mods={{ type_top_with_number_secondary: isSecondary }}
                />
            );
        }

        if (isBestsellerCounter) {
            const isSecondary = itemPosition > 3;

            return <ProductLabel type="number" text={itemPosition} mods={{ type_number_secondary: isSecondary }} />;
        }

        if (bestsellerLabel && bestsellerLabel.length) {
            const [{ label_link } = {}] = bestsellerLabel;

            return <ProductLabel type="top" labelLink={label_link} />;
        }

        return null;
    }

    renderDiscountLabel() {
        const { product } = this.props;
        const { label_link, custom_class, custom_css, content } =
            getCodazonLabelByType({ codazon_labels: getCodazonLabelWithDiscount(product) }, 'discount') || {};

        if (!custom_class) {
            return null;
        }

        return (
            <ProductLabel
                type={getProductLabelType(custom_class)}
                text={content}
                customCSS={custom_css}
                labelLink={label_link}
            />
        );
    }

    renderReviews() {
        const { layout } = this.props;

        return (
            <div block="ProductCard" elem="Reviews" mods={{ layout }}>
                {this.renderRatingSummary(true)}
            </div>
        );
    }

    renderShippingPriceInfo() {
        return (
            <div block="ProductCard" elem="ShippingTimeInfo">
                {__('Shipping to bookstore')} <span>0 zł</span>
            </div>
        );
    }

    renderCardContent() {
        const {
            renderContent,
            isLightVariant,
            isPromotedVariant,
            isWishlistItem,
            device: { isMobile, isTablet } = {},
            isMenuVariant,
            isWishlistPriceAlert,
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (isPromotedVariant) {
            return this.renderCardLinkWrapper(
                <>
                    <div block="ProductCard" elem="LinkInnerWrapper" mods={{ isPromotedVariant: !!isPromotedVariant }}>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                {this.renderPicture()}
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            {this.renderName(false)}
                            {this.renderAuthor(false)}
                            {this.renderPublisherOrProducer()}
                            {this.renderPrice()}
                            {this.renderProductLabels()}
                        </div>
                    </div>
                    <div block="ProductCard" elem="Footer">
                        {this.renderAddToCart()}
                    </div>
                </>
            );
        }

        if (isLightVariant) {
            return this.renderCardLinkWrapper(
                <div block="ProductCard" elem="LinkInnerWrapper" mods={{ isLightVariant }}>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderName(false)}
                        {this.renderAuthor(false)}
                        {this.renderPublisherOrProducer()}
                        {this.renderProductLabels()}
                    </div>
                </div>
            );
        }

        if (isMenuVariant) {
            return this.renderCardLinkWrapper(
                <div block="ProductCard" elem="LinkInnerWrapper" mods={{ isMenuVariant }}>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderName(false)}
                        {this.renderAuthor(false)}
                        {this.renderPublisherOrProducer()}
                        {this.renderPrice()}
                        {this.renderProductLabels()}
                    </div>
                </div>
            );
        }

        if ((isMobile || isTablet) && isWishlistItem) {
            return this.renderCardLinkWrapper(
                <div block="ProductCard" elem="LinkInnerWrapper">
                    <div block="ProductCard" elem="TopLabelsWrapper">
                        {isWishlistItem && this.renderWishlistOldPrice()}
                        {this.renderBestsellerLabel()}
                        {this.renderDiscountLabel()}
                    </div>
                    {this.renderReviews()}
                    {this.renderWishlistItemRemove()}
                    <div block="ProductCard" elem="TopContent">
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                {this.renderPicture()}
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            {this.renderName(false)}
                            {this.renderAuthor(false)}
                            {this.renderPublisherOrProducer()}
                            {this.renderPrice()}
                            {this.renderProductLabels()}
                            {this.renderShippingTimeInfo()}
                            {this.renderConfigurableOptions()}
                            {this.renderExtraInfo()}
                        </div>
                    </div>
                    <div block="ProductCard" elem="Footer">
                        {this.renderWishlistItemCommentField()}
                        {this.renderAddToCart()}
                        {this.renderWishlistItemPrice()}
                    </div>
                </div>
            );
        }

        if (isWishlistPriceAlert) {
            if (isMobile || isTablet) {
                return this.renderCardLinkWrapper(
                    <div
                        block="ProductCard"
                        elem="LinkInnerWrapper"
                        mods={{ isWishlistPriceAlert: !!isWishlistPriceAlert }}
                    >
                        <div block="ProductCard" elem="TopLabelsWrapper">
                            {this.renderWishlistOldPrice()}
                            {this.renderBestsellerLabel()}
                            {this.renderDiscountLabel()}
                        </div>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                {this.renderPicture()}
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            {this.renderName(false)}
                            {this.renderAuthor(false)}
                            {this.renderPublisherOrProducer()}
                            {this.renderPrice()}
                            {this.renderProductLabels()}
                            {this.renderShippingTimeInfo()}
                            {this.renderAddToCart()}
                        </div>
                    </div>
                );
            }

            return this.renderCardLinkWrapper(
                <div
                    block="ProductCard"
                    elem="LinkInnerWrapper"
                    mods={{ isWishlistPriceAlert: !!isWishlistPriceAlert }}
                >
                    <div block="ProductCard" elem="TopLabelsWrapper">
                        {this.renderWishlistOldPrice()}
                        {this.renderBestsellerLabel()}
                        {this.renderDiscountLabel()}
                    </div>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderName(false)}
                        {this.renderAuthor(false)}
                        {this.renderPublisherOrProducer()}
                        {this.renderPrice()}
                        {this.renderProductLabels()}
                        {this.renderShippingTimeInfo()}
                    </div>
                    <div block="ProductCard" elem="VisibleOnHover">
                        {this.renderVisibleOnHover()}
                    </div>
                </div>
            );
        }

        if (isMobile || isTablet) {
            return this.renderCardLinkWrapper(
                <div block="ProductCard" elem="LinkInnerWrapper">
                    <div block="ProductCard" elem="FigureReview">
                        {isWishlistItem && this.renderWishlistOldPrice()}
                        <figure block="ProductCard" elem="Figure">
                            <div block="ProductCard" elem="TopLabelsWrapper">
                                {this.renderDiscountLabel()}
                                {this.renderBestsellerLabel()}
                            </div>
                            {this.renderPicture()}
                            {this.renderReviews()}
                            {isWishlistItem && this.renderWishlistItemRemove()}
                            {this.renderProductCardWishlistButton()}
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderName(false)}
                        {this.renderAuthor(false)}
                        {this.renderPublisherOrProducer()}
                        {this.renderPrice()}
                        {this.renderProductLabels()}
                        {this.renderShippingTimeInfo()}
                        {this.renderAddToCart()}
                    </div>
                </div>
            );
        }

        return this.renderCardLinkWrapper(
            <div block="ProductCard" elem="LinkInnerWrapper">
                <div block="ProductCard" elem="FigureReview">
                    {isWishlistItem && this.renderWishlistOldPrice()}
                    <figure block="ProductCard" elem="Figure">
                        <div block="ProductCard" elem="TopLabelsWrapper">
                            {this.renderDiscountLabel()}
                            {this.renderBestsellerLabel()}
                        </div>
                        {this.renderPicture()}
                        {this.renderReviews()}
                        {isWishlistItem && this.renderWishlistItemRemove()}
                        {this.renderProductCardWishlistButton()}
                    </figure>
                </div>
                <div block="ProductCard" elem="Content">
                    {this.renderName(false)}
                    {this.renderAuthor(false)}
                    {this.renderPublisherOrProducer()}
                    {this.renderPrice()}
                    {this.renderProductLabels()}
                    {this.renderShippingTimeInfo()}
                </div>
                <div block="ProductCard" elem="VisibleOnHover">
                    {this.renderVisibleOnHover()}
                </div>
            </div>
        );
    }

    requiresConfiguration() {
        const {
            product: { type_id: type, options = [], links_purchased_separately },
        } = this.props;

        const configureBundleAndGrouped = type === PRODUCT_TYPE.bundle || type === PRODUCT_TYPE.grouped;
        const configureConfig =
            (type === PRODUCT_TYPE.configurable &&
                // eslint-disable-next-line max-len
                Object.keys(super.getConfigurableAttributes()).length !==
                    Object.keys(this.getConfigurableAttributes()).length) ||
            // eslint-disable-next-line max-len
            (type === PRODUCT_TYPE.configurable &&
                Object.values(this.getConfigurableAttributes()).some((value) => value.attribute_values.length === 0));
        const configureCustomize = options?.some(({ required = false }) => required);
        const configureDownloadableLinks = PRODUCT_TYPE.downloadable && links_purchased_separately === 1;

        return configureBundleAndGrouped || configureConfig || configureCustomize || configureDownloadableLinks;
    }

    renderRatingSummary() {
        const {
            product: { review_summary: { rating_summary, review_count } = {} },
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return <ProductReviewRating summary={rating_summary || 0} count={review_count} isShort />;
    }

    renderShortDescription() {
        const { product } = this.props;
        const { short_description: { html } = {} } = product;

        if (html)
            return (
                <div block="ProductCard" elem="ExtraInfoItem">
                    <span>{__('Description: ')}</span>
                    {html}
                </div>
            );

        return null;
    }

    renderCardListContent() {
        const { layout, renderContent, isWishlistShared, product } = this.props;
        const { codazon_labels } = product || {};
        const withLabels = !!codazon_labels;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (isWishlistShared) {
            return this.renderCardLinkWrapper(
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content" mods={{ layout }}>
                        <div block="ProductCard" elem="MainInfo">
                            {this.renderMainDetails()}
                            {this.renderPrice()}
                        </div>
                    </div>
                    {this.renderWishlistComment()}
                    <div block="ProductCard" elem="ActionWrapper">
                        {this.renderAddToCart()}
                        {this.renderProductCardWishlistButton()}
                    </div>
                </>
            );
        }

        return this.renderCardLinkWrapper(
            <div block="ProductCard" elem="Link">
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        {this.renderPicture()}
                    </figure>
                </div>
                <div block="ProductCard" elem="Content" mods={{ layout }}>
                    <div block="ProductCard" elem="MainInfo">
                        <div>
                            {this.renderProductLabels()}
                            {this.renderMainDetails()}
                            <div block="ProductCard" elem="AuthorWrapper">
                                {__('Author:')}
                                {this.renderAuthor(false)}
                            </div>
                        </div>
                        <div block="ProductCard" elem="MainInfoReviews" mods={{ withLabels }}>
                            {this.renderReviews()}
                            {this.renderProductActions()}
                        </div>
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        {this.renderPrice()}
                        {this.renderExtraInfoList()}
                    </div>
                    <div block="ProductCard" elem="ActionWrapper">
                        {this.renderAddToCart()}
                        {this.renderShippingTimeInfo()}
                        {this.renderShippingPriceInfo()}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            children,
            mix,
            layout,
            handleCardHover,
            handleCardLeave,
            isHovered,
            isLightVariant,
            isMenuVariant,
            isPromotedVariant,
            setValidator,
            product,
        } = this.props;
        const { product_other_edition: productOtherEdition } = product || {};

        if (layout === LIST_LAYOUT) {
            return (
                <li
                    block="ProductCard"
                    mods={{ layout, isProductOtherEdition: productOtherEdition?.length > 0 }}
                    mix={mix}
                    ref={setValidator}
                    {...getProductIdentificationAttribs(product)}
                >
                    {this.renderCardListContent()}
                </li>
            );
        }

        return (
            <li
                block="ProductCard"
                mods={{
                    layout,
                    isHovered,
                    isLightVariant: !!isLightVariant,
                    isMenuVariant: !!isMenuVariant,
                    isPromotedVariant: !!isPromotedVariant,
                    isProductOtherEdition: productOtherEdition?.length > 0,
                }}
                mix={mix}
                onMouseEnter={handleCardHover}
                onMouseLeave={handleCardLeave}
                ref={setValidator}
                {...getProductIdentificationAttribs(product)}
            >
                {this.renderCardContent()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </li>
        );
    }
}

export default ProductCard;
