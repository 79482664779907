import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import { NEWSLETTER_POPUP_ID } from 'Component/NewsletterPopup/NewsletterPopup.config';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import Popup from 'Component/Popup';

import { NEWSLETTER_POPUP_INFO_CMS_ID } from './NewsletterPopup.config';

import './NewsletterPopup.style';

/** @namespace SwiatKsiazkiBasic/Component/NewsletterPopup/Component */
export class NewsletterPopup extends PureComponent {
    renderContent() {
        return (
            <div>
                <CmsBlock identifier={NEWSLETTER_POPUP_INFO_CMS_ID} />
                <NewsletterSubscription />
            </div>
        );
    }

    render() {
        const { onNewsletterPopupClose, isMobile } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <Popup
                id={NEWSLETTER_POPUP_ID}
                clickOutside={false}
                mix={{ block: 'NewsletterPopup' }}
                onClose={onNewsletterPopupClose}
                noHeaderStateChange
            >
                {this.renderContent()}
            </Popup>
        );
    }
}

export default NewsletterPopup;
