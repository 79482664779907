/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Gtm/Query/Config/Query/ConfigQuery */
export class ConfigQuery {
    _getConfigFields = () => ([
        'enabled',
        'gtm_id',
        'googleOptimizeEnabled',
        'googleOptimizeId',
        'googleOptimizeAsyncEnabled',
        'antiFlickerEnabled',
        this._getEventField()
    ]);

    _getEventField = () => new Field('events')
        .addFieldList(this._getEventFields());

    _getEventFields = () => ([
        'gtm_general_init',
        'gtm_impressions_batch_limit',
        'gtm_impressions',
        // * Combined these 3 vvv to this ^^^
        // 'gtm_impressions_plp',
        // 'gtm_impressions_search',
        // 'gtm_impressions_home,
        'gtm_site_search',
        'gtm_site_search_started',
        'gtm_product_click',
        'gtm_product_detail',
        'gtm_product_add_to_cart',
        'gtm_product_remove_from_cart',
        'gtm_purchase',
        'gtm_checkout',
        'gtm_checkout_option',
        'gtm_user_login',
        'gtm_user_login_attempt',
        'gtm_user_register',
        'gtm_user_register_attempt',
        'gtm_user_logout',
        'gtm_not_found',
        'gtm_page_reload',
        'gtm_error_message',
        'gtm_sort',
        'gtm_add_to_wishlist',
        'gtm_remove_from_wishlist',
        'gtm_remove_all_items_from_wishlist',
        'gtm_filter_applied',
        'gtm_filter_removed',
        'gtm_all_filters_removed'
    ]);

    getGtmField = () => new Field('getGtm')
        .setAlias('gtm')
        .addFieldList(this._getConfigFields());
}

export default new ConfigQuery();
