import Cookies from 'js-cookie';
import { PureComponent } from 'react';

import { getBooleanValue } from 'Util/Data';

import CookieObservable from './CookieObservable';

export const AMASTY_ALLOWED_COOKIE_NAME = 'amcookie_allowed';
export const AMASTY_DISALLOWED_COOKIE_NAME = 'amcookie_disallowed';

export const AMASTY_COOKIE_GROUP = {
    essential: 'essential',
    marketing: 'marketing',
    statistic: 'statistic',
    unclassified: 'unclassified',
};

/** @namespace SwiatKsiazkiBasic/Util/Cookie/getCookie */
export const getCookie = (name) => Cookies.get(name);

/** @namespace SwiatKsiazkiBasic/Util/Cookie/hasCookie */
export const hasCookie = (name) => !!Cookies.get(name);

/** @namespace SwiatKsiazkiBasic/Util/Cookie/setCookie */
export const setCookie = (name, value) => Cookies.set(name, value);

/** @namespace SwiatKsiazkiBasic/Util/Cookie/removeCookie */
export const removeCookie = (name) => Cookies.remove(name);

/** @namespace SwiatKsiazkiBasic/Util/Cookie/getAmastyDisallowed */
export const getAmastyDisallowed = () => getCookie(AMASTY_DISALLOWED_COOKIE_NAME)?.split(',') || [];

/** @namespace SwiatKsiazkiBasic/Util/Cookie/hasAmastyAllowedCookie */
export const hasAmastyAllowedCookie = () => hasCookie(AMASTY_ALLOWED_COOKIE_NAME);

/** @namespace SwiatKsiazkiBasic/Util/Cookie/getAmastyAllowedCookie */
export const getAmastyAllowedCookie = () =>
    getCookie(AMASTY_ALLOWED_COOKIE_NAME)
        ?.split(',')
        ?.map((identifier) => identifier) ?? [];

/** @namespace SwiatKsiazkiBasic/Util/Cookie/updateGoogleConsent */
export const updateGoogleConsent = (identifiers = getAmastyAllowedCookie()) => {
    if (typeof window.gtag === 'function') {
        window.gtag('consent', 'update', {
            ad_storage: identifiers?.includes(AMASTY_COOKIE_GROUP.marketing) ? 'granted' : 'denied',
            ad_user_data: identifiers?.includes(AMASTY_COOKIE_GROUP.marketing) ? 'granted' : 'denied',
            ad_personalization: identifiers?.includes(AMASTY_COOKIE_GROUP.marketing) ? 'granted' : 'denied',
            analytics_storage: identifiers?.includes(AMASTY_COOKIE_GROUP.statistic) ? 'granted' : 'denied',
        });
    }
};

/** @namespace SwiatKsiazkiBasic/Util/Cookie/canRunScript */
export const canRunScript = (identifier) =>
    (getBooleanValue(window.isCookieRestriction) === true && getAmastyAllowedCookie().includes(identifier)) ||
    getBooleanValue(window.isCookieRestriction) === false ||
    process.env.NODE_ENV === 'development';

/** @namespace SwiatKsiazkiBasic/Util/Cookie/appendCookieAttributes */
export const appendCookieAttributes = (identifier, { element, src, type, ...options } = {}) => {
    if (element) {
        Object.entries(options).forEach(([key, value]) => {
            element.setAttribute(key, value);
        });

        if (src) {
            element.setAttribute('data-cookie-section', identifier);

            if (canRunScript(identifier)) {
                element.setAttribute('data-cookie-src', src);
            } else {
                element.setAttribute('src', src);
            }
        } else {
            element.setAttribute('data-cookie-section', identifier);
            element.setAttribute('type', canRunScript(identifier) ? type || 'text/javascript' : 'text/plain');
        }

        return element;
    }

    if (src) {
        return {
            ...options,
            ...(canRunScript(identifier) ? { src } : { 'data-cookie-src': src }),
            'data-cookie-section': identifier,
        };
    }

    return {
        ...options,
        'data-cookie-section': identifier,
        type: canRunScript(identifier) ? type || 'text/javascript' : 'text/plain',
    };
};

/** @namespace SwiatKsiazkiBasic/Util/Cookie/runScripts */
export const runScripts = (reloadPage = false) => {
    if (getBooleanValue(window.isCookieRestriction) === true) {
        getAmastyDisallowed()?.forEach((cookie) => {
            removeCookie(cookie);
        });
    }

    if (reloadPage) {
        location.reload();
    } else {
        Array.from(CookieObservable.observers.keys()).forEach((identifier) => {
            if (canRunScript(identifier)) {
                CookieObservable.notify(identifier);
            }
        });

        document.querySelectorAll('[data-cookie-section]').forEach((element) => {
            if (canRunScript(element.getAttribute('data-cookie-section'))) {
                const { parentElement } = element;
                const cloneElement = element;

                if (
                    cloneElement.hasAttribute('data-cookie-src') ||
                    cloneElement.getAttribute('type') === 'text/plain'
                ) {
                    if (cloneElement.hasAttribute('data-cookie-src')) {
                        cloneElement.setAttribute('src', cloneElement.getAttribute('data-cookie-src'));
                        cloneElement.removeAttribute('data-cookie-src');
                    } else if (cloneElement.getAttribute('type') === 'text/plain') {
                        cloneElement.setAttribute('type', 'text/javascript');
                    }

                    parentElement.removeChild(element);
                    parentElement.appendChild(cloneElement);
                }
            } else {
                const { parentElement } = element;
                const cloneElement = element;

                if (cloneElement.hasAttribute('src') || cloneElement.getAttribute('type') === 'text/javascript') {
                    if (cloneElement.hasAttribute('src')) {
                        cloneElement.setAttribute('data-cookie-src', cloneElement.getAttribute('src'));
                        cloneElement.removeAttribute('src');
                    } else {
                        cloneElement.setAttribute('type', 'text/plain');
                    }

                    parentElement.removeChild(element);
                    parentElement.appendChild(cloneElement);
                }
            }
        });
    }
};

export default {
    appendCookieAttributes,
    runScripts,
    canRunScript,
    onAcceptedCookie: CookieObservable.subscribe,
    initializeCookie: () => {
        runScripts();
    },
    Render: class extends PureComponent {
        componentDidMount() {
            runScripts();
        }

        render() {
            const { children } = this.props;

            return typeof children === 'function' ? children({ appendCookieAttributes }) : children;
        }
    },
};
