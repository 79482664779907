/** @namespace SwiatKsiazkiBasic/Component/Pixel/Script/track */
export const track = (eventName, params, eventType = 'track') => {
    if (!window.fbq) {
        throw new Error('Facebook Pixel not loaded');
    }

    const eventId = `${eventName}.${Math.floor(Math.random() * 1000000)}.${Date.now()}`;

    try {
        window.fbq(eventType, eventName, params, { eventID: eventId });
    } catch (error) {
        console.error(`Failed to track ${eventName}:`, error);
    }
};
