import {
    ACCOUNT_CONSENTS,
    ACCOUNT_CUSTOMER_CARD_ADD,
    ACCOUNT_CUSTOMER_CARDS,
    ACCOUNT_CUSTOMER_CARDS_LIST,
    ACCOUNT_INFORMATION,
    ACCOUNT_REVIEWS,
    ADDRESS_BOOK,
    ADDRESS_BOOK_ADD,
    ADDRESS_BOOK_EDIT,
    FIRST_SECTION,
    MY_ACCOUNT,
    MY_DOWNLOADABLE,
    MY_ORDERS,
    MY_WISHLIST,
    NEWSLETTER_SUBSCRIPTION,
    SECOND_SECTION,
    THIRD_SECTION,
} from 'Type/Account.type';

import { SMS_SUBSCRIPTION } from '../../type/Account.type';

export * from 'SourceRoute/MyAccount/MyAccount.config';

export const BOOKSHOP_GROUP_ID = 5;

export const ACCOUNT_FORGOT_PASSWORD_URL = '/customer/account/forgotpassword';
export const ACCOUNT_FORGOT_PASSWORD_SUCCESS_URL = 'customer/account/forgotpasswordsuccess';
export const ACCOUNT_URL = '/customer/account/info';
export const ACCOUNT_MENU_RUL = '/customer/account-menu';
export const ACCOUNT_ADDRESS_BOOK_ADD_URL = '/customer/address/new';
export const ACCOUNT_ADDRESS_BOOK_EDIT_URL = '/customer/address/edit';
export const ACCOUNT_ADDRESS_BOOK_URL = '/customer/address';
export const ACCOUNT_REVIEWS_URL = '/reviews';
export const ACCOUNT_WISHLIST_URL = '/wishlist';
export const ACCOUNT_MY_DOWNLOADABLE_URL = '/downloadable/customer/products';
export const ACCOUNT_ORDER_REPAYMENT_URL = '/sales/order/repayview/order_id';
export const ACCOUNT_CUSTOMER_CARDS_URL = '/cards';
export const ACCOUNT_CUSTOMER_CARD_ADD_URL = '/card/new';
export const ACCOUNT_SALES_PRINT = '/sales/order/print/order_id';
export const CONFIRM_ACCOUNT_URL = '/customer/account/confirm';
export const ACCOUNT_LOGIN_AS_CUSTOMER_URL = '/loginasuser/';

export const ACCOUNT_TAB_MAP = {
    [MY_ACCOUNT]: {
        url: 'customer/account/info',
        tabName: __('My data'),
        tabIconName: 'account_user',
        section: FIRST_SECTION,
        isBookshop: true,
    },
    [ADDRESS_BOOK]: {
        url: '/customer/address',
        tabName: __('Address Book'),
        tabIconName: 'saved_addresses',
        section: SECOND_SECTION,
        isFullUrl: true,
    },
    [ADDRESS_BOOK_ADD]: {
        tabName: __('Add address'),
        hideInSidebar: true,
    },
    [ADDRESS_BOOK_EDIT]: {
        tabName: __('Edit address'),
        hideInSidebar: true,
    },
    [MY_ORDERS]: {
        url: '/sales/order/history',
        tabName: __('My Orders'),
        tabIconName: 'orders',
        section: FIRST_SECTION,
        isFullUrl: true,
        isBookshop: true,
    },
    [MY_DOWNLOADABLE]: {
        url: '/downloadable/customer/products',
        tabName: __('My Downloadable'),
        tabIconName: 'virtual_shelf',
        section: FIRST_SECTION,
        isFullUrl: true,
    },
    [NEWSLETTER_SUBSCRIPTION]: {
        url: '/newsletter/manage',
        tabName: __('Newsletter Subscription'),
        tabIconName: 'newsletter',
        section: THIRD_SECTION,
        isFullUrl: true,
    },
    [SMS_SUBSCRIPTION]: {
        url: '/sms/manage',
        tabName: __('SMS Subscription'),
        tabIconName: 'mobile_icon',
        section: THIRD_SECTION,
        isFullUrl: true,
    },
    [ACCOUNT_REVIEWS]: {
        url: '/reviews',
        tabName: __('Product reviews'),
        tabIconName: 'reviews',
        isFullUrl: true,
    },
    [MY_WISHLIST]: {
        url: '/wishlist',
        tabName: __('My Wish List'),
        tabIconName: 'account_wishlist',
        section: FIRST_SECTION,
        isFullUrl: true,
        isBookshop: true,
    },
    [ACCOUNT_INFORMATION]: {
        url: '/edit',
        tabName: __('Account Information'),
        title: __('My data'),
        section: SECOND_SECTION,
    },
    [ACCOUNT_CUSTOMER_CARDS]: {
        url: '/cards',
        tabName: __('My cards'),
        tabIconName: 'my_cards',
        section: FIRST_SECTION,
        isFullUrl: true,
    },
    [ACCOUNT_CUSTOMER_CARDS_LIST]: {
        url: '/cards/:id',
        tabName: __('My cards'),
        tabIconName: 'my_cards',
        section: FIRST_SECTION,
        isFullUrl: true,
        hideInSidebar: true,
    },
    [ACCOUNT_CUSTOMER_CARD_ADD]: {
        url: '/card/new/:key',
        tabName: __('My cards'),
        tabIconName: 'my_cards',
        section: FIRST_SECTION,
        isFullUrl: true,
        hideInSidebar: true,
    },
    [ACCOUNT_CONSENTS]: {
        url: '/customer/consents',
        tabName: __('Consent management'),
        tabIconName: 'settings',
        section: SECOND_SECTION,
        isFullUrl: true,
    },
};
