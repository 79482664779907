import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PRELOAD_FINISHED } from 'util/Preload';

import { showNotification } from 'Store/Notification/Notification.action';
import { getCacheValue, hasCacheValue } from 'Util/Cache';
import { isPreloading, PRELOAD_KEYS } from 'Util/Preload';
import { getErrorMessage } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import AdvoxSliderQuery from '../../query/AdvoxSlider.query';
import AdvoxSlider from './AdvoxSlider.component';
import { HOMEPAGE_POSITION } from './AdvoxSlider.config';

/** @namespace SwiatKsiazkiBasic/Slider/Components/AdvoxSlider/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Slider/Components/AdvoxSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace SwiatKsiazkiBasic/Slider/Components/AdvoxSlider/Container */
export class AdvoxSliderContainer extends DataContainer {
    static propTypes = {
        position: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
    };

    state = {
        slider: {},
        isLoading: false,
        isDragging: false,
    };

    containerFunctions = {
        setDragging: this.setDragging.bind(this),
    };

    componentDidMount() {
        this.getSliderData();
    }

    __construct(props) {
        const { code } = props;

        super.__construct(props, `AdvoxSliderContainer_${code}`);
    }

    handlePreloadEvent() {
        if (hasCacheValue(PRELOAD_KEYS.HOME_PAGE_SLIDER)) {
            this.setState({ slider: getCacheValue(PRELOAD_KEYS.HOME_PAGE_SLIDER) });
        } else {
            this.getSliderData();
        }
    }

    async getSliderData() {
        const { position, code, showNotification } = this.props;

        if (position === HOMEPAGE_POSITION && isPreloading()) {
            document.addEventListener(PRELOAD_FINISHED, this.handlePreloadEvent.bind(this));
        } else if (position === HOMEPAGE_POSITION && hasCacheValue(PRELOAD_KEYS.HOME_PAGE_SLIDER)) {
            this.handlePreloadEvent();
        } else {
            this.fetchData(
                AdvoxSliderQuery.getSliderQuery(position, code),
                ({ slider }) => {
                    this.setState({
                        slider,
                        isLoading: false,
                    });
                },
                (error) => {
                    showNotification('error', getErrorMessage(error));
                    this.setState({ isLoading: false });
                }
            );
        }
    }

    componentWillUnmount() {
        document.removeEventListener(PRELOAD_FINISHED, this.handlePreloadEvent.bind(this));
    }

    setDragging(isDragging) {
        this.setState({ isDragging });
    }

    containerProps = () => {
        const { slider, isLoading, isDragging } = this.state;

        return {
            slider,
            isLoading,
            isDragging,
        };
    };

    render() {
        return <AdvoxSlider {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvoxSliderContainer);
