import { isHomePageUrl } from '@scandipwa/scandipwa/src/util/Url';
import { CMS_PAGE } from 'component/Header/Header.config';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import { LocationType, MatchType } from 'Type/Router.type';
import { scrollToTop } from 'Util/Browser';
import { debounce } from 'Util/Request';

import CmsPage from './CmsPage.component';
import { LOADING_TIME } from './CmsPage.config';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace SwiatKsiazkiBasic/Route/CmsPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isOffline: state.OfflineReducer.isOffline,
    isLoading: state.UrlRewritesReducer.isLoading,
    page: state.UrlRewritesReducer.urlRewrite.page,
});

/** @namespace SwiatKsiazkiBasic/Route/CmsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) =>
        BreadcrumbsDispatcher.then(({ default: dispatcher }) => dispatcher.updateWithCmsPage(breadcrumbs, dispatch)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    toggleBreadcrumbs: (isActive) => {
        BreadcrumbsDispatcher.then(({ default: dispatcher }) => dispatcher.update([], dispatch));
        dispatch(toggleBreadcrumbs(isActive));
    },
});

/** @namespace SwiatKsiazkiBasic/Route/CmsPage/Container */
export class CmsPageContainer extends PureComponent {
    static propTypes = {
        match: MatchType.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        setBigOfflineNotice: PropTypes.func.isRequired,
        location: LocationType.isRequired,
        toggleBreadcrumbs: PropTypes.func.isRequired,
        isOnlyPlaceholder: PropTypes.bool,
        isBreadcrumbsActive: PropTypes.bool,
        isHomePage: PropTypes.bool,
    };

    static defaultProps = {
        isOnlyPlaceholder: false,
        isBreadcrumbsActive: true,
        isHomePage: false,
    };

    setOfflineNoticeSize = this.setOfflineNoticeSize.bind(this);

    componentDidMount() {
        const { isOffline, isLoading } = this.props;

        scrollToTop();

        if (isOffline && isLoading) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        this.updateBreadcrumbs();

        if (!isLoading) {
            this.onPageLoad();
        }
    }

    componentDidUpdate(prevProps) {
        const { isLoading } = this.props;

        if (prevProps.isLoading !== isLoading && isLoading === false) {
            this.onPageLoad();
        }
    }

    updateBreadcrumbs() {
        const { toggleBreadcrumbs, isBreadcrumbsActive } = this.props;

        toggleBreadcrumbs(isBreadcrumbsActive);
    }

    setOfflineNoticeSize() {
        const { setBigOfflineNotice } = this.props;
        const { isLoading } = this.props;

        if (isLoading) {
            setBigOfflineNotice(true);
        } else {
            setBigOfflineNotice(false);
        }
    }

    onPageLoad() {
        const {
            location: { pathname },
            updateMeta,
            setHeaderState,
            updateBreadcrumbs,
            page,
        } = this.props;

        const { content_heading, meta_title, title, meta_description, meta_keywords } = page || {};

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(page);
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
        });

        if (!isHomePageUrl(pathname)) {
            setHeaderState({
                name: CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack(),
            });
        }
    }

    containerProps() {
        const { isBreadcrumbsActive, page, isLoading, isHomePage } = this.props;

        return {
            isHomePage,
            isBreadcrumbsActive,
            isLoading,
            isPageLoaded: Object.keys(page).length > 0,
            page,
        };
    }

    render() {
        return <CmsPage {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
