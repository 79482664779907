import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { CUSTOMER_ACCOUNT } from 'Component/Header/Header.config';
import { FB_ADD_TO_WISHLIST, PixelObservable } from 'Component/Pixel';
import { ACCOUNT_LOGIN_URL } from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer,
    WishlistDispatcher,
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { isSignedIn } from 'Util/Auth';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace SwiatKsiazkiBasic/Component/ProductWishlistButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile,
    productsInWishlistArray: state.WishlistReducer.productsInWishlistArray,
    customer: state.MyAccountReducer.customer,
});

/** @namespace SwiatKsiazkiBasic/Component/ProductWishlistButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    removeProductFromWishlist: (options) =>
        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)),
});
/** @namespace SwiatKsiazkiBasic/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    getWishlistItem(productSku) {
        const { productsInWishlistArray } = this.props;

        if (!productsInWishlistArray) {
            return null;
        }

        const { id, sku } = productsInWishlistArray?.find((product) => product?.sku === productSku) || {};

        return id ? { wishlist: { id }, sku } : undefined;
    }

    async toggleProductInWishlist(add = true) {
        const {
            location,
            history,
            isMobile,
            product,
            magentoProduct,
            magentoProduct: [{ sku }] = [{}],
            showOverlay,
            addProductToWishlist,
            removeProductFromWishlist,
            wishlistId,
        } = this.props;

        const { isWishListToggle } = this.state;

        if (!isSignedIn()) {
            window.overlay = {
                redirect: location,
                action: () => this.toggleProductInWishlist(add),
                title: __('Log in to add products to the wishlist'),
            };

            if (isMobile) {
                history.push({
                    pathname: appendWithStoreCode(ACCOUNT_LOGIN_URL),
                });
            } else {
                showOverlay(CUSTOMER_ACCOUNT);
            }
        } else {
            this.setWishlistButtonLoading(true);

            if (isWishListToggle) {
                return;
            }

            this.setState({ isWishListToggle: true });

            const wishlistItem = this.getWishlistItem(sku);

            if (add) {
                if (!wishlistItem) {
                    await addProductToWishlist({
                        items: magentoProduct,
                        wishlistId,
                    });

                    PixelObservable.send(FB_ADD_TO_WISHLIST, product);
                }
            } else if (wishlistItem) {
                const {
                    wishlist: { id: itemId },
                } = wishlistItem;

                await removeProductFromWishlist({
                    item_id: itemId,
                    name: magentoProduct[0]?.name,
                });
            }

            this.setState({ isWishListToggle: false });
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer));
