import React, { PureComponent } from 'react';

import Popup from 'Component/Popup';
import { eventPersist } from 'Util/Element';

import { CHECKOUT_DECLARATION_POPUP } from './CheckoutDeclarationPopup.config';

import './CheckoutDeclarationPopup.style';

/** @namespace SwiatKsiazkiBasic/Component/CheckoutDeclarationPopup/Component */
export class CheckoutDeclarationPopup extends PureComponent {
    handleClickOption(value) {
        const {
            setDigitalConsent,
            agreement: { agreement_id } = {},
            hideActiveOverlay,
            goToPreviousNavigationState,
        } = this.props;

        return eventPersist(() => {
            setDigitalConsent(agreement_id, value);
            hideActiveOverlay();
            goToPreviousNavigationState();
        });
    }

    renderHeading() {
        return (
            <h2
                block="CheckoutDeclarationPopup"
                elem="Heading"
                dangerouslySetInnerHTML={{
                    __html: __('You will receive digital products <br/><span>in 14 days</span>'),
                }}
            />
        );
    }

    renderDescription() {
        return (
            <p
                block="CheckoutDeclarationPopup"
                elem="Description"
                dangerouslySetInnerHTML={{
                    __html: __(
                        'You have not consented to the early commencement of the fulfillment of the ordered digital content, resulting in the receipt of digital products <b>after 14 days.</b>'
                    ),
                }}
            />
        );
    }

    renderOptions() {
        return (
            <div block="CheckoutDeclarationPopup" elem="Options">
                <div block="CheckoutDeclarationPopup" elem="Option" mods={{ type: 'agree' }}>
                    <h3 block="CheckoutDeclarationPopup" elem="OptionHeading">
                        {__("I'm changing my mind")}
                    </h3>
                    <button
                        block="CheckoutDeclarationPopup"
                        elem="OptionButton"
                        type="button"
                        onClick={this.handleClickOption('1')}
                    >
                        {__('I agree')}
                    </button>
                    <p
                        block="CheckoutDeclarationPopup"
                        elem="OptionDescription"
                        dangerouslySetInnerHTML={{
                            __html: __('I will receive digital products <br/><span>immediately</span>'),
                        }}
                    />
                </div>
                <div block="CheckoutDeclarationPopup" elem="Option" mods={{ type: 'disagree' }}>
                    <h3 block="CheckoutDeclarationPopup" elem="OptionHeading">
                        {__("I'm not changing my mind")}
                    </h3>
                    <button
                        block="CheckoutDeclarationPopup"
                        elem="OptionButton"
                        type="button"
                        onClick={this.handleClickOption('0')}
                    >
                        {__('I disagree')}
                    </button>
                    <p
                        block="CheckoutDeclarationPopup"
                        elem="OptionDescription"
                        dangerouslySetInnerHTML={{
                            __html: __('I will receive digital products <br/><span>after 14 days</span>'),
                        }}
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <Popup
                id={CHECKOUT_DECLARATION_POPUP}
                mix={{ block: 'CheckoutDeclarationPopup' }}
                contentMix={{ block: 'CheckoutDeclarationPopup', elem: 'Content' }}
                clickOutside={false}
            >
                {this.renderHeading()}
                {this.renderDescription()}
                {this.renderOptions()}
            </Popup>
        );
    }
}

export default CheckoutDeclarationPopup;
