import CartQuery from 'Query/Cart.query';
import { CheckoutQuery as SourceCheckoutQuery } from 'SourceQuery/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace SwiatKsiazkiBasic/Query/Checkout/Query */
export class CheckoutQuery extends SourceCheckoutQuery {
    getPayUCardsSecureFormConfig() {
        return new Field('getPayUCardsSecureFormConfig').addFieldList(this._getPayUCardsSecureFormConfigFields());
    }

    _getPayUCardsSecureFormConfigFields() {
        return [
            'isActive',
            'logoSrc',
            'storedCards',
            'transferKey',
            'termsUrl',
            'language',
            this._getSecureFormConfigField(),
        ];
    }

    _getSecureFormConfigField() {
        return new Field('secureFormConfig').addFieldList(this._getSecureFormConfigFields());
    }

    _getSecureFormConfigFields() {
        return ['env', 'posId', 'storeCard'];
    }

    getPayUMethodsQuery() {
        return new Field('getPayUMethods').addFieldList(this._getPayUMethodsFields());
    }

    _getPayUMethodsFields() {
        return ['brandImageUrl', 'name', 'sort', 'status', 'value'];
    }

    _getEstimatedShippingFields() {
        return [
            ...super._getEstimatedShippingFields(),
            'delivery_date',
            'has_packages',
            'requires_simple_address',
            'icon_url',
        ];
    }

    _getSaveAddressInformationFields() {
        return [this._getPaymentMethodsField(), this._getTotalsField()];
    }

    _getTotalsField() {
        return new Field('totals')
            .addFieldList(this._getTotalsFields())
            .addFieldList(CartQuery._getOrderDividedFields());
    }

    _getTotalsFields() {
        return [
            'subtotal',
            'tax_amount',
            'base_grand_total',
            'grand_total',
            'discount_amount',
            'shipping_amount',
            'subtotal_incl_tax',
            'shipping_incl_tax',
            'quote_currency_code',
            'shipping_tax_amount',
            'subtotal_with_discount',
            'shipping_discount_amount',
            'shipping_method',
            'shipping_method_title',
            'shipping_carrier_title',
            'is_virtual',
            this._getTotalItemField(),
            this._getGiftCardDetailsField(),
            this._getDiscountDetailsField(),
            this._getCartPriceDetails(),
        ];
    }

    _getTotalItemFields() {
        return [
            'qty',
            'name',
            'price',
            'item_id',
            'sku',
            'options',
            'tax_amount',
            'tax_percent',
            'price_incl_tax',
            'discount_amount',
            'discount_percent',
            'is_virtual',
        ];
    }

    _getGiftCardDetailsField() {
        return new Field('giftcard_details').addFieldList(this._getGiftCardDetailsFields());
    }

    _getGiftCardDetailsFields() {
        return ['amount', 'label', this._getGiftCardsField()];
    }

    _getGiftCardsField() {
        return new Field('gift_cards').addFieldList(this._getGiftCardsFields());
    }

    _getGiftCardsFields() {
        return ['card_number', 'giftcard_quote_id', 'label', 'amount', 'id', 'customer_card_id'];
    }

    _getDiscountDetailsField() {
        return new Field('discount_details').addFieldList([
            'amount',
            'label',
            this._getSalesRuleField(),
            this._getSeriesDiscounts(),
            this._getSetsDiscounts(),
        ]);
    }

    _getCartPriceDetails() {
        return new Field('cart_price_details').addFieldList(['suggested_starting_price', 'saved_amount']);
    }

    _getSalesRuleField() {
        return new Field('salesrule_discounts').addFieldList(['name', 'amount']);
    }

    _getSeriesDiscounts() {
        return new Field('series_discounts').addFieldList(['name', 'amount']);
    }

    _getSetsDiscounts() {
        return new Field('sets_discounts').addFieldList(['name', 'amount']);
    }

    _getOrderField() {
        return new Field('order').addFieldList(['order_id', 'order_date', this._getOrderAdditionalInformationField()]);
    }

    _getOrderAdditionalInformationField() {
        return new Field('additional_information').addFieldList(['url']);
    }

    getDivideOrderMutation(guestCartId) {
        return new Field('divideOrder')
            .addArgument('guestCartId', 'String', guestCartId)
            .addFieldList(['is_operation_success', 'error_message']);
    }

    getCancelOrderDivisionMutation(guestCartId) {
        return new Field('cancelOrderDivision')
            .addArgument('guestCartId', 'String', guestCartId)
            .addFieldList(['is_operation_success', 'error_message']);
    }

    getValidateAndSaveDeclaration(countryId) {
        return new Field('validateAndSaveDeclaration')
            .addArgument('countryId', 'String!', countryId)
            .addFieldList(['validation', 'error_message', 'country_id']);
    }

    getPlaceOrderMutation(guestCartId, agreements) {
        const mutation = new Field('s_placeOrder').setAlias('placeOrder').addField(this._getOrderField());

        if (agreements?.length) {
            mutation.addArgument('agreements', '[AgreementData]', agreements);
        }

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', guestCartId);
        }

        return mutation;
    }

    getSetShippingAddressesOnCart(input) {
        return new Field('setShippingAddressesOnCart')
            .addArgument('input', 'SetShippingAddressesOnCartInput!', input)
            .addField(this._getCartField())
            .setAlias('shippingAddress');
    }
}

export default new CheckoutQuery();
