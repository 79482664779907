/* eslint-disable no-unused-vars, @scandipwa/scandipwa-guidelines/export-level-one */

import AdvoxSliderQuery from '@swiat-ksiazki-basic/slider/src/query/AdvoxSlider.query';

import {
    FOOTER_COPYRIGHT_CMS_ID,
    FOOTER_LINKS_CMS_ID,
    FOOTER_PAYMENTS_CMS_ID,
    FOOTER_SEO_CMS_ID,
    FOOTER_SOCIAL_ICONS_CMS_ID,
} from 'Component/Footer/Footer.config';
import { MOBILE_MENU_LINKS_CMS_ID } from 'Component/Menu/Menu.config';
import { NEWSLETTER_INFO_CMS_ID } from 'Component/Newsletter/Newsletter.config';
import CmsBlockQuery from 'Query/CmsBlock.query';
import RouteQuery from 'Query/Route.query';
import { HOMEPAGE_SLIDER_CMS_ID, HOMEPAGE_USP_CMS_ID } from 'Route/HomePage/HomePage.config';
import { initializeCache, setCacheValue } from 'Util/Cache';
import { prepareQuery } from 'Util/Query';
import { executePreload } from 'Util/Request/Request';

import { CMS_BLOCS_PRELOADED } from '../../component/CmsBlock/CmsBlock.config';
import { HOMEPAGE_MAIN_SLIDER_ADDITIONAL_TEXT_CMS_ID } from '../../route/HomePage/HomePage.config';

let preloaded = false;
let preloadedRoute = false;

export const PRELOAD_FINISHED = 'PRELOAD_FINISHED';
export const PRELOAD_ROUTE_FINISHED = 'PRELOAD_ROUTE_FINISHED';

export const PRELOAD_KEYS = {
    HOME_PAGE_SLIDER: 'HOME_PAGE_SLIDER',
    BLOCKS: 'BLOCKS',
    ROUTE: 'ROUTE',
};

export const ACTION_NAMES = {
    PWA_ROUTER: 'PWA_ROUTER',
};

initializeCache();

/** @namespace SwiatKsiazkiBasic/Util/Preload/isPreloading */
export const isPreloading = () => preloaded !== true;

/** @namespace SwiatKsiazkiBasic/Util/Preload/isPreloadingRoute */
export const isPreloadingRoute = () => preloadedRoute !== true && window?.actionName?.type === ACTION_NAMES.PWA_ROUTER;

const preloadRouteData = async () => {
    try {
        if (window?.actionName?.type === ACTION_NAMES.PWA_ROUTER || !window?.location?.pathname) {
            return;
        }

        const queries = [RouteQuery.getQuery({ urlParam: window?.location?.pathname })];

        const { route } = await executePreload(prepareQuery(queries));

        [[PRELOAD_KEYS.ROUTE, route]].forEach(([key, value]) => {
            setCacheValue(key, value);
        });
    } catch (error) {
        console.error('Something went wrong during the preload route', error);
    } finally {
        preloadedRoute = true;
        document.dispatchEvent(new Event(PRELOAD_ROUTE_FINISHED));
    }
};

const preloadCommonData = async () => {
    try {
        const queries = [
            CmsBlockQuery.getQuery({
                identifiers: CMS_BLOCS_PRELOADED,
            }),
            AdvoxSliderQuery.getSliderQuery('homepage', 'home-slider'),
        ];

        const { cmsBlocks, slider } = await executePreload(prepareQuery(queries));

        [
            [PRELOAD_KEYS.BLOCKS, cmsBlocks?.items || []],
            [PRELOAD_KEYS.HOME_PAGE_SLIDER, slider],
        ].forEach(([key, value]) => {
            if (value) {
                setCacheValue(key, value);
            }
        });
    } catch (error) {
        console.error('Something went wrong during the preload common data', error);
    } finally {
        preloaded = true;
        document.dispatchEvent(new Event(PRELOAD_FINISHED));
    }
};

setTimeout(preloadRouteData, 0);
setTimeout(preloadCommonData, 0);
