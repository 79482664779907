import {
    FOOTER_COPYRIGHT_CMS_ID,
    FOOTER_LINKS_CMS_ID,
    FOOTER_PAYMENTS_CMS_ID,
    FOOTER_SEO_CMS_ID,
    FOOTER_SOCIAL_ICONS_CMS_ID,
} from 'Component/Footer/Footer.config';
import { MOBILE_MENU_LINKS_CMS_ID } from 'Component/Menu/Menu.config';
import { NEWSLETTER_INFO_CMS_ID } from 'Component/Newsletter/Newsletter.config';
import {
    HOMEPAGE_MAIN_SLIDER_ADDITIONAL_TEXT_CMS_ID,
    HOMEPAGE_SLIDER_CMS_ID,
    HOMEPAGE_USP_CMS_ID,
} from 'Route/HomePage/HomePage.config';

export const CMS_BLOCS_PRELOADED = [
    HOMEPAGE_SLIDER_CMS_ID,
    HOMEPAGE_USP_CMS_ID,
    FOOTER_COPYRIGHT_CMS_ID,
    FOOTER_LINKS_CMS_ID,
    FOOTER_PAYMENTS_CMS_ID,
    FOOTER_SEO_CMS_ID,
    FOOTER_SOCIAL_ICONS_CMS_ID,
    NEWSLETTER_INFO_CMS_ID,
    MOBILE_MENU_LINKS_CMS_ID,
    HOMEPAGE_MAIN_SLIDER_ADDITIONAL_TEXT_CMS_ID,
];
