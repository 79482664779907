export * from 'SourceType/Account.type';

export const ADDRESS_BOOK_ADD = 'address-new';
export const ADDRESS_BOOK_EDIT = 'address-edit';
export const ACCOUNT_REVIEWS = 'my-reviews';
export const ACCOUNT_REVIEWS_DETAILS_PAGE = 'my-reviews-details-page';
export const ACCOUNT_CUSTOMER_CARDS = 'cards';
export const ACCOUNT_CUSTOMER_CARDS_LIST = 'cards-list';
export const ACCOUNT_CUSTOMER_CARD_ADD = 'card-add';

export const MY_ORDER_REPAYMENT = 'my-order-repayment';
export const ACCOUNT_CONSENTS = 'my-account-consents';
export const SMS_SUBSCRIPTION = 'sms-subscription';
