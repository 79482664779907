import { Field } from 'Util/Query';

/** @namespace SwiatKsiazkiBasic/Query/Slider/Query */
export class SliderQuery {
    getQuery(identifier, alias) {
        const query = new Field('sliderByIdentifier')
            .addArgument('identifier', 'String!', identifier)
            .addFieldList(this._getSliderFields());

        if (alias) {
            query.setAlias(alias);
        }

        return query;
    }

    _getSliderFields() {
        return ['autorotate_time', this._getSlidersField()];
    }

    _getSlidersField() {
        return new Field('slides').addFieldList(this._getSlidersFields());
    }

    _getSlidersFields() {
        return [
            'additional_text',
            'cta_link',
            'date_from',
            'date_to',
            'header',
            'image',
            'is_active',
            'desktop_image',
            'mobile_image',
            'tablet_image',
        ];
    }
}

export default new SliderQuery();
