import SliderQuery from 'Query/Slider.query';
import DataContainer from 'Util/Request/DataContainer';

import TopBarWidgetComponent from './TopBarWidget.component';

/** @namespace SwiatKsiazkiBasic/Component/TopBarWidget/Container */
export class TopBarWidgetContainer extends DataContainer {
    state = {
        mainSlider: null,
    };

    componentDidMount() {
        this.getWidgets();
    }

    __construct(props) {
        super.__construct(props, `TopBarWidgetContainer`);
    }

    getWidgets() {
        this.fetchData(
            [SliderQuery.getQuery('top-bar-widget', 'main')],
            ({ main: mainSlider } = {}) => {
                this.setState({
                    mainSlider,
                });
            },
            (error) => {
                if (Array.isArray(error)) {
                    this.setState({
                        mainSlider: error?.at(1)?.main || {},
                    });
                } else {
                    this.setState({
                        mainSlider: {},
                    });
                }
            }
        );
    }

    containerProps() {
        const { mainSlider } = this.state;

        return {
            mainSlider,
        };
    }

    render() {
        return <TopBarWidgetComponent {...this.containerProps()} />;
    }
}

export default TopBarWidgetContainer;
