import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import SliderQuery from 'Query/Slider.query';
import DataContainer from 'Util/Request/DataContainer';

import HomeWidgetsComponent from './HomeWidgets.component';

/** @namespace SwiatKsiazkiBasic/Component/HomeWidgets/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace SwiatKsiazkiBasic/Component/HomeWidgets/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/HomeWidgets/Container */
export class HomeWidgetsContainer extends DataContainer {
    state = {
        mainSlider: null,
        midSlider: null,
        bottomSlider: null,
    };

    componentDidMount() {
        this.getWidgets();
    }

    __construct(props) {
        super.__construct(props, `HomeWidgetsContainer`);
    }

    getWidgets() {
        this.fetchData(
            [
                SliderQuery.getQuery('home-widget-main', 'main'),
                SliderQuery.getQuery('home-widget-mid', 'mid'),
                SliderQuery.getQuery('home-widget-bottom', 'bottom'),
            ],
            ({ main: mainSlider, mid: midSlider, bottom: bottomSlider } = {}) => {
                this.setState({
                    mainSlider,
                    midSlider,
                    bottomSlider,
                });
            },
            ([, { main: mainSlider, mid: midSlider, bottom: bottomSlider } = {}]) => {
                this.setState({
                    mainSlider,
                    midSlider,
                    bottomSlider,
                });
            }
        );
    }

    containerProps() {
        const { mainSlider, midSlider, bottomSlider } = this.state;
        const { device } = this.props;

        return {
            mainSlider,
            midSlider,
            bottomSlider,
            device,
        };
    }

    render() {
        return <HomeWidgetsComponent {...this.containerProps()} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeWidgetsContainer));
