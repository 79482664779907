// import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutQuery from 'Query/Checkout.query';
import ConfigQuery from 'Query/Config.query';
import { updateDeclaration } from 'Store/Cart/Cart.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { prepareQuery } from 'Util/Query';
import { executeGet, fetchMutation, getErrorMessage } from 'Util/Request';
import transformCountriesToOptions from 'Util/Store/Transform';

import { CHECKOUT_DECLARATION_POPUP } from '../CheckoutDeclarationPopup/CheckoutDeclarationPopup.config';
import CheckoutDeclaration from './CheckoutDeclaration.component';
/** @namespace SwiatKsiazkiBasic/Component/CheckoutDeclaration/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    countries: transformCountriesToOptions(state.ConfigReducer.countries || []),
    totals: state.CartReducer.cartTotals,
});

/** @namespace SwiatKsiazkiBasic/Component/CheckoutDeclaration/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showDeclarationPopup: (payload) => dispatch(showPopup(CHECKOUT_DECLARATION_POPUP, payload)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateDeclaration: (data) => dispatch(updateDeclaration(data)),
});

/** @namespace SwiatKsiazkiBasic/Component/CheckoutDeclaration/Container */
export class CheckoutDeclarationContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        selectedCountryId: '',
        agreement: {},
        firstValidation: true,
        isLoading: false,
    };

    containerFunctions = {
        validateAndSaveDeclaration: this.validateAndSaveDeclaration.bind(this),
        showDeclarationPopup: this.showDeclarationPopup.bind(this),
    };

    countryInputRef = createRef();

    componentDidMount() {
        this.requestAgreement();
    }

    componentDidUpdate(prevProps) {
        const {
            totals: { selected_declaration_country: prevSelectedDeclarationCountry },
        } = prevProps;
        const {
            totals: { selected_declaration_country: selectedDeclarationCountry },
        } = this.props;
        const { firstValidation } = this.state;

        if (prevSelectedDeclarationCountry !== selectedDeclarationCountry) {
            if (firstValidation) {
                this.validateAndSaveDeclaration(selectedDeclarationCountry);
            }

            this.countryInputRef.current?.dispatchEvent(new CustomEvent('validate'));
        }
    }

    containerProps = () => {
        const {
            countries,
            setDigitalConsent,
            digitalConsent,
            totals: { selected_declaration_country, last_declaration_validation_result } = {},
            checkoutStep,
        } = this.props;
        const { selectedCountryId, agreement, isLoading } = this.state;

        return {
            countries,
            setDigitalConsent,
            selectedCountryId,
            selectedDeclarationCountry: selected_declaration_country,
            lastDeclarationValidationResult: last_declaration_validation_result,
            digitalConsent,
            agreement,
            checkoutStep,
            isLoading,
            countryInputRef: this.countryInputRef,
        };
    };

    async requestAgreement() {
        const { showNotification } = this.props;

        try {
            const { checkoutAgreements } = await executeGet(
                prepareQuery(ConfigQuery.getCheckoutAgreements('digitalConsent')),
                'CheckoutAgreement'
            );

            if (checkoutAgreements?.length) {
                this.setState({
                    agreement: checkoutAgreements[0],
                });
            }
        } catch (e) {
            showNotification('error', getErrorMessage(e));
        }
    }

    async validateAndSaveDeclaration(countryId) {
        const { updateDeclaration, showNotification } = this.props;
        const currentCountryId = countryId || '';

        this.setState({
            selectedCountryId: currentCountryId,
            firstValidation: false,
            isLoading: true,
        });

        try {
            const { validateAndSaveDeclaration } = await fetchMutation(
                CheckoutQuery.getValidateAndSaveDeclaration(currentCountryId)
            );
            const { error_message } = validateAndSaveDeclaration;

            updateDeclaration(validateAndSaveDeclaration);

            if (error_message) {
                showNotification('error', error_message);
            }
        } catch (e) {
            showNotification('error', getErrorMessage(e));
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    showDeclarationPopup() {
        const { showDeclarationPopup } = this.props;

        showDeclarationPopup({
            title: __('Confirm declaration'),
        });
    }

    render() {
        return <CheckoutDeclaration {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeclarationContainer);
