export * from 'SourceStore/Wishlist/Wishlist.action';

export const SET_WISHLIST_PRICE_DROP = 'SET_WISHLIST_PRICE_DROP';
export const UPDATE_ALL_PRODUCTS_IN_WISHLIST_ARRAY = 'UPDATE_ALL_PRODUCTS_IN_WISHLIST_ARRAY';
export const UPDATE_ALL_PRODUCTS_IN_WISHLIST_COUNT = 'UPDATE_ALL_PRODUCTS_IN_WISHLIST_COUNT';

export const PRICE_LIST_ERROR_MESSAGE = 'Unable to save wishlist price alert.';

/** @namespace SwiatKsiazkiBasic/Store/Wishlist/Action/setWishlistPriceDrop */
export const setWishlistPriceDrop = (isWishlistHavePriceDrop) => ({
    type: SET_WISHLIST_PRICE_DROP,
    isWishlistHavePriceDrop,
});

/** @namespace SwiatKsiazkiBasic/Store/Wishlist/Action/updateAllProductsInWishlistCount */
export const updateAllProductsInWishlistCount = (productsInWishlistCount, productsInWishlistCountByCategory) => ({
    type: UPDATE_ALL_PRODUCTS_IN_WISHLIST_COUNT,
    productsInWishlistCount,
    productsInWishlistCountByCategory,
});

/** @namespace SwiatKsiazkiBasic/Store/Wishlist/Action/updateAllProductsInWishlistArray */
export const updateAllProductsInWishlistArray = (productsInWishlistArray) => ({
    type: UPDATE_ALL_PRODUCTS_IN_WISHLIST_ARRAY,
    productsInWishlistArray,
});
