import lazy from '@loadable/component';
import { PureComponent, Suspense } from 'react';

import CheckoutDeclaration from 'Component/CheckoutDeclaration';
import CheckoutSteps from 'Component/CheckoutSteps';
import ContentWrapper from 'Component/ContentWrapper';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { CHECKOUT, CHECKOUT_SUCCESS } from 'Component/Header/Header.config';
import Loader from 'Component/Loader';
import { FB_INITIATE_CHECKOUT, PixelObservable } from 'Component/Pixel';
import { BILLING_STEP, CHECKOUT_URL, DETAILS_STEP, SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { sortCountries } from 'Util/Address';
import { scrollToTop } from 'Util/Browser';
import { appendWithStoreCode } from 'Util/Url';

import './Checkout.style.scss';
import './Checkout.override.style.scss';

export const CartCoupon = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "checkout" */
        'Component/CartCoupon'
    )
);

export const CmsBlock = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "checkout" */
        'Component/CmsBlock'
    )
);

export const CheckoutOrderSummary = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "checkout" */
        'Component/CheckoutOrderSummary'
    )
);

export const CheckoutBilling = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "checkout" */
        'Component/CheckoutBilling'
    )
);

export const CheckoutShipping = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "checkout" */
        'Component/CheckoutShipping'
    )
);

export const CheckoutSuccess = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "checkout" */
        'Component/CheckoutSuccess'
    )
);

export const ExpandableContent = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "checkout" */
        'Component/ExpandableContent'
    )
);

/** @namespace SwiatKsiazkiBasic/Route/Checkout/Component */
export class Checkout extends PureComponent {
    stepMap = {
        [SHIPPING_STEP]: {
            number: 1,
            title: __('Delivery method and payment'),
            url: '/shipping',
            render: this.renderShippingStep.bind(this),
            areTotalsVisible: true,
        },
        [BILLING_STEP]: {
            number: 2,
            title: __('Summary'),
            url: '/billing',
            render: this.renderBillingStep.bind(this),
            areTotalsVisible: true,
        },
        [DETAILS_STEP]: {
            title: __('Thank you for your purchase!'),
            mobileTitle: __('Order details'),
            url: '/success',
            render: this.renderDetailsStep.bind(this),
            areTotalsVisible: false,
        },
    };

    componentDidMount() {
        const { checkoutTotals, checkoutStep, history } = this.props;
        const { url } = this.stepMap[checkoutStep];
        const { location: { search = '' } = {} } = history;

        this.updateHeader();

        if (checkoutTotals?.items?.length) {
            PixelObservable.send(FB_INITIATE_CHECKOUT, checkoutTotals);
        }

        history.replace(appendWithStoreCode(`${CHECKOUT_URL}${url}${search}`));

        document.documentElement.classList.add('hiddenChat');
    }

    componentDidUpdate(prevProps) {
        const { checkoutTotals, checkoutStep } = this.props;
        const { checkoutStep: prevCheckoutStep } = prevProps;

        if (checkoutStep !== prevCheckoutStep) {
            this.updateHeader();
            this.updateStep();
        }

        if (
            !this.pixelInitialeCheckoutEventSend &&
            !prevProps?.checkoutTotals?.items?.length &&
            checkoutTotals?.items?.length
        ) {
            PixelObservable.send(FB_INITIATE_CHECKOUT, checkoutTotals);
            this.pixelInitialeCheckoutEventSend = true;
        }
    }

    componentWillUnmount() {
        document.documentElement.classList.remove('hiddenChat');
    }

    updateHeader() {
        const { setHeaderState, checkoutStep, goBack } = this.props;
        const { mobileTitle, title } = this.stepMap[checkoutStep];

        setHeaderState({
            name: checkoutStep === DETAILS_STEP ? CHECKOUT_SUCCESS : CHECKOUT,
            title: mobileTitle || title,
            onBackClick: () => goBack(),
        });
    }

    updateStep() {
        const { checkoutStep, history } = this.props;
        const { url } = this.stepMap[checkoutStep];
        const { location: { search = '' } = {} } = history;

        history.push(appendWithStoreCode(`${CHECKOUT_URL}${url}${search}`));
        scrollToTop({ behavior: 'smooth' });
    }

    renderCheckoutSteps() {
        const { checkoutStep } = this.props;

        return <CheckoutSteps checkoutStep={checkoutStep} />;
    }

    renderDeliveryCountry() {
        const { countries, selectedCountry, setSelectedCountry } = this.props;

        return (
            <div block="Checkout" elem="DeliveryCountry">
                <p block="Checkout" elem="DeliveryCountryLabel">
                    {__('Country of delivery')}
                </p>
                <Field
                    type={FIELD_TYPE.select}
                    options={sortCountries(countries)}
                    value={selectedCountry}
                    attr={{ value: selectedCountry, noPlaceholder: true }}
                    events={{ onChange: setSelectedCountry }}
                />
            </div>
        );
    }

    renderShippingStep() {
        const {
            shippingMethods,
            shippingAddress,
            onShippingEstimationFieldsChange,
            saveAddressInformation,
            isDeliveryOptionsLoading,
            onPasswordChange,
            onCreateUserChange,
            onEmailChange,
            isCreateUser,
            estimateAddress,
            isPickInStoreMethodSelected,
            handleSelectDeliveryMethod,
            cartTotalSubPrice,
            onShippingMethodSelect,
            onStoreSelect,
            selectedStoreAddress,
            isDigitalConsentRequired,
            selectedCountry,
            checkoutStep,
            isGuestEmailSaved,
            isSignedIn,
            onEmailConfirmChange,
            confirmEmail,
            email,
            isSimpleAddressRequired,
            setShippingSpot,
            isBookstore,
        } = this.props;
        const isBilling = checkoutStep === BILLING_STEP;

        return (
            <>
                {!isBookstore ? (
                    <>
                        <h2 block="Checkout" elem="Heading">
                            {__('Delivery method and payment')}
                        </h2>
                        {this.renderDeliveryCountry()}
                    </>
                ) : null}
                <Suspense fallback={<Loader />}>
                    <CheckoutShipping
                        isLoading={isDeliveryOptionsLoading}
                        shippingMethods={shippingMethods}
                        shippingAddress={shippingAddress}
                        cartTotalSubPrice={cartTotalSubPrice}
                        saveAddressInformation={saveAddressInformation}
                        onShippingEstimationFieldsChange={onShippingEstimationFieldsChange}
                        onShippingMethodSelect={onShippingMethodSelect}
                        onPasswordChange={onPasswordChange}
                        onCreateUserChange={onCreateUserChange}
                        onEmailChange={onEmailChange}
                        isCreateUser={isCreateUser}
                        estimateAddress={estimateAddress}
                        handleSelectDeliveryMethod={handleSelectDeliveryMethod}
                        isPickInStoreMethodSelected={isPickInStoreMethodSelected}
                        onStoreSelect={onStoreSelect}
                        selectedStoreAddress={selectedStoreAddress}
                        isDigitalConsentRequired={isDigitalConsentRequired}
                        selectedCountry={selectedCountry}
                        isBilling={isBilling}
                        isGuestEmailSaved={isGuestEmailSaved}
                        isSignedIn={isSignedIn}
                        onEmailConfirmChange={onEmailConfirmChange}
                        confirmEmail={confirmEmail}
                        email={email}
                        isSimpleAddressRequired={isSimpleAddressRequired}
                        setShippingSpot={setShippingSpot}
                        declaration={this.renderDeclaration()}
                    />
                </Suspense>
            </>
        );
    }

    renderBillingStep() {
        const {
            setLoading,
            setDetailsStep,
            shippingAddress,
            paymentMethods = [],
            savePaymentInformation,
            selectedShippingMethod,
            isDigitalConsentRequired,
            selectedCountry,
            isDeliveryCountryPL,
            isSimpleAddressRequired,
            shippingSpot,
            email,
            getPaymentMethods,
            updateBillingTelephone,
        } = this.props;

        return (
            <Suspense fallback={<Loader />}>
                <CheckoutBilling
                    setLoading={setLoading}
                    paymentMethods={paymentMethods}
                    setDetailsStep={setDetailsStep}
                    shippingAddress={shippingAddress}
                    savePaymentInformation={savePaymentInformation}
                    selectedShippingMethod={selectedShippingMethod}
                    isDigitalConsentRequired={isDigitalConsentRequired}
                    selectedCountry={selectedCountry}
                    isDeliveryCountryPL={isDeliveryCountryPL}
                    isSimpleAddressRequired={isSimpleAddressRequired}
                    shippingSpot={shippingSpot}
                    email={email}
                    getPaymentMethods={getPaymentMethods}
                    declaration={this.renderDeclaration()}
                    updateBillingTelephone={updateBillingTelephone}
                />
            </Suspense>
        );
    }

    renderDeclaration() {
        const {
            isNeedDeclaration,
            setDigitalConsent,
            digitalConsent,
            checkoutStep,
            totals: { is_virtual },
        } = this.props;

        if (!isNeedDeclaration || (!is_virtual && checkoutStep === BILLING_STEP) || checkoutStep === DETAILS_STEP) {
            return null;
        }

        return (
            <CheckoutDeclaration
                setDigitalConsent={setDigitalConsent}
                digitalConsent={digitalConsent}
                checkoutStep={checkoutStep}
            />
        );
    }

    renderDetailsStep() {
        const {
            orderID,
            guestCartId,
            isEmailAvailable,
            email,
            billingAddress: { firstname, lastname },
        } = this.props;

        return (
            <Suspense fallback={<Loader />}>
                <CheckoutSuccess
                    email={email}
                    firstName={firstname}
                    lastName={lastname}
                    isEmailAvailable={isEmailAvailable}
                    orderID={orderID}
                    guestCartId={guestCartId}
                />
            </Suspense>
        );
    }

    renderDiscountCode() {
        const {
            totals: { coupon_code, items },
            checkoutStep,
        } = this.props;

        if (!items || items.length < 1 || checkoutStep !== BILLING_STEP) {
            return null;
        }

        return (
            <ExpandableContent
                heading={__('Have a discount code?')}
                mix={{ block: 'Checkout', elem: 'Discount' }}
                isArrow
            >
                <CartCoupon couponCode={coupon_code} />
            </ExpandableContent>
        );
    }

    renderStep() {
        const { checkoutStep } = this.props;
        const { render } = this.stepMap[checkoutStep];

        if (render) {
            return render();
        }

        return null;
    }

    renderLoader() {
        const { isLoading } = this.props;

        return <Loader isLoading={isLoading} />;
    }

    renderSummary(showOnMobile = false, onlyItems = false) {
        const {
            checkoutTotals,
            checkoutStep,
            paymentTotals,
            isMobile,
            onCouponCodeUpdate,
            isLoading,
            selectedShippingMethod,
            paymentMethods,
            shippingAddress,
            billingAddress,
            isSimpleAddressRequired,
        } = this.props;
        const { areTotalsVisible } = this.stepMap[checkoutStep];
        const { renderPromo } = this.renderPromo(true) || {};

        if (!areTotalsVisible || (showOnMobile && !isMobile) || (!showOnMobile && isMobile)) {
            return null;
        }

        return (
            <CheckoutOrderSummary
                checkoutStep={checkoutStep}
                totals={checkoutTotals}
                paymentTotals={paymentTotals}
                onCouponCodeUpdate={onCouponCodeUpdate}
                renderCmsBlock={renderPromo}
                showItems
                isLoading={isLoading}
                isCheckoutLoading={isLoading}
                selectedShippingMethod={selectedShippingMethod}
                paymentMethods={paymentMethods}
                onlyItems={onlyItems}
                shippingAddress={shippingAddress}
                billingAddress={billingAddress}
                isSimpleAddressRequired={isSimpleAddressRequired}
            />
        );
    }

    renderPromo(showOnMobile = false) {
        const { checkoutStep, isMobile } = this.props;
        const isBilling = checkoutStep === BILLING_STEP;

        if ((!showOnMobile && isMobile) || checkoutStep === DETAILS_STEP) {
            return null;
        }

        const { checkout_content: { [isBilling ? 'checkout_billing_cms' : 'checkout_shipping_cms']: promo } = {} } =
            window.contentConfiguration;

        if (!promo) {
            return null;
        }

        return (
            <div block="Checkout" elem="Promo">
                <CmsBlock identifier={promo} />
            </div>
        );
    }

    renderStoreInPickUpMethod() {
        const {
            isPickInStoreMethodSelected,
            handleSelectDeliveryMethod,
            checkoutStep,
            isInStoreActivated,
            totals: { is_in_store_pickup_available: isInStorePickupAvailable },
        } = this.props;

        if (checkoutStep !== SHIPPING_STEP || !isInStoreActivated) {
            return null;
        }

        if (!isInStorePickupAvailable) {
            return null;
        }

        return (
            <div block="Checkout" elem="DeliverySelect">
                <button
                    block="Checkout"
                    elem="ShippingButton"
                    mix={{ block: 'Button', mods: { isHollow: !isPickInStoreMethodSelected } }}
                    type="button"
                    disabled={!isPickInStoreMethodSelected}
                    onClick={handleSelectDeliveryMethod}
                >
                    {__('Shipping')}
                </button>
                <button
                    block="Checkout"
                    elem="PickInStore"
                    mix={{ block: 'Button', mods: { isHollow: isPickInStoreMethodSelected } }}
                    type="button"
                    disabled={isPickInStoreMethodSelected}
                    onClick={handleSelectDeliveryMethod}
                >
                    {__('Pick in Store')}
                </button>
            </div>
        );
    }

    render() {
        const { checkoutStep, isCustomerLoaded } = this.props;

        return (
            <main block="Checkout" mods={{ step: checkoutStep }}>
                {isCustomerLoaded ? (
                    <Suspense fallback={<Loader />}>
                        {this.renderCheckoutSteps()}
                        <ContentWrapper wrapperMix={{ block: 'Checkout', elem: 'Wrapper' }} label={__('Checkout page')}>
                            {this.renderSummary(true, true)}
                            <div>
                                <div block="Checkout" elem="Step">
                                    {this.renderStoreInPickUpMethod()}
                                    {this.renderStep()}
                                    {this.renderLoader()}
                                </div>
                            </div>
                            {this.renderSummary(true)}
                            <div>
                                {this.renderSummary()}
                                {this.renderPromo()}
                            </div>
                        </ContentWrapper>
                    </Suspense>
                ) : (
                    <Loader />
                )}
            </main>
        );
    }
}

export default Checkout;
