import parser from 'html-react-parser';
import { PureComponent } from 'react';

import CheckoutDeclarationPopup from 'Component/CheckoutDeclarationPopup';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import FieldGroup from 'Component/FieldGroup';
import Html from 'Component/Html';
import Loader from 'Component/Loader';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { sortCountries } from 'Util/Address';

import './CheckoutDeclaration.style';

/** @namespace SwiatKsiazkiBasic/Component/CheckoutDeclaration/Component */
export class CheckoutDeclaration extends PureComponent {
    renderHeading() {
        return (
            <div block="CheckoutDeclaration" elem="Heading">
                <Html content={`<h2>${__('<span>Digital</span> product delivery information')}</h2>`} />
            </div>
        );
    }

    renderDeclarationForm() {
        const {
            countries,
            validateAndSaveDeclaration,
            selectedCountryId,
            selectedDeclarationCountry,
            lastDeclarationValidationResult,
            countryInputRef,
        } = this.props;

        return (
            <div block="CheckoutDeclaration" elem="DeclarationForm">
                <span block="CheckoutDeclaration" elem="DeclarationLabel">
                    {__('The country from which I purchase:')}
                </span>
                <Field
                    type={FIELD_TYPE.select}
                    attr={{
                        id: 'country',
                        name: 'country',
                        selectPlaceholder: `-- ${__('Select')} --`,
                        value: selectedDeclarationCountry || selectedCountryId,
                    }}
                    options={sortCountries(countries)}
                    mix={{ block: 'CheckoutDeclaration', elem: 'Select' }}
                    validationRule={{
                        isRequired: true,
                        match: () => {
                            if ((selectedCountryId || selectedDeclarationCountry) && !lastDeclarationValidationResult) {
                                return __('The selected country is incompatible with your IP address');
                            }

                            return true;
                        },
                    }}
                    validateOn={['onChange', 'onSubmit']}
                    events={{ onChange: validateAndSaveDeclaration }}
                    elemRef={countryInputRef}
                />
            </div>
        );
    }

    renderDigitalConsentOptions() {
        const {
            showDeclarationPopup,
            setDigitalConsent,
            digitalConsent = {},
            agreement: { agreement_id },
        } = this.props;

        const agreeLabel = __(
            `<b>Yes, I agree</b> - I will receive digital products <b class="CheckoutDeclaration-DigitalConsentAgree">immediately.</b>`
        ).toString();

        const disagreeLabel = __(
            `<b>No, I disagree</b> - I will receive digital products <b class="CheckoutDeclaration-DigitalConsentDisagree">after 14 days.</b>`
        ).toString();

        return (
            <FieldGroup
                attr={{
                    mix: { block: 'CheckoutDeclaration', elem: 'DigitalConsentOptions' },
                }}
                validationRule={{
                    isRequired: true,
                }}
                validateOn={['onChange', 'onSubmit']}
            >
                <Field
                    type={FIELD_TYPE.radio}
                    label={parser(agreeLabel)}
                    attr={{
                        id: 'agreement-1',
                        name: 'agreement',
                        checked: digitalConsent.value === '1',
                        value: '1',
                    }}
                    events={{
                        onChange: (e) => setDigitalConsent(agreement_id, e.target.value),
                    }}
                />
                <Field
                    type={FIELD_TYPE.radio}
                    label={parser(disagreeLabel)}
                    attr={{
                        id: 'agreement-0',
                        name: 'agreement',
                        checked: digitalConsent.value === '0',
                        value: '0',
                    }}
                    events={{
                        onChange: (e) => setDigitalConsent(agreement_id, e.target.value),
                        onClick: showDeclarationPopup,
                    }}
                />
            </FieldGroup>
        );
    }

    renderDigitalConsentForm() {
        const {
            digitalConsent = {},
            agreement: { content },
        } = this.props;

        return (
            <div block="CheckoutDeclaration" elem="AgreementForm">
                <div block="CheckoutDeclaration" elem="AgreementFormWrapper">
                    <p block="CheckoutDeclaration" elem="AgreementContent" mods={{ info: true }}>
                        {content ? (
                            <Html content={content} />
                        ) : (
                            __(
                                "I agree that the digital content on the Orderer's Virtual Shelf will be delivered to me before the expiration of the 14-day withdrawal period, which will result in the loss of the aforementioned right.*."
                            )
                        )}
                    </p>
                    {this.renderDigitalConsentOptions()}
                    {digitalConsent.value === '0' && (
                        <div block="CheckoutDeclaration" elem="AgreementAdditional">
                            <p block="CheckoutDeclaration" elem="AgreementContent" mods={{ disagreement: true }}>
                                {__(
                                    '*In case of disagreement, digital content will be delivered to the Virtual Shelf after 14 days of payment.'
                                )}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderDigitalConsentPopup() {
        const { agreement, setDigitalConsent } = this.props;

        return <CheckoutDeclarationPopup setDigitalConsent={setDigitalConsent} agreement={agreement} />;
    }

    render() {
        const { checkoutStep, isLoading } = this.props;

        const variant = checkoutStep === SHIPPING_STEP ? 'shipping' : 'billing';

        return (
            <div block="CheckoutDeclaration" mods={{ variant }}>
                <Loader isLoading={isLoading} isFullPage />
                {this.renderHeading()}
                {this.renderDeclarationForm()}
                {this.renderDigitalConsentForm()}
                {this.renderDigitalConsentPopup()}
            </div>
        );
    }
}

export default CheckoutDeclaration;
