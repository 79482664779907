import PropTypes from 'prop-types';
import { createContext } from 'react';
import { getCacheValue, hasCacheValue } from 'util/Cache';
import { isPreloading, PRELOAD_FINISHED } from 'util/Preload';

import CmsBlockQuery from 'Query/CmsBlock.query';
import DataContainer from 'Util/Request/DataContainer';

export const CmsBlockContext = createContext({});

/** @namespace SwiatKsiazkiBasic/Component/CmsBlockProvider/Container */
export class CmsBlockProviderContainer extends DataContainer {
    static propTypes = {
        name: PropTypes.string,
        children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        identifiers: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        name: undefined,
        identifiers: [],
    };

    static getContext = () => CmsBlockContext;

    static contextType = CmsBlockContext;

    state = {
        isLoading: true,
        blocks: [],
    };

    __construct(props) {
        super.__construct(props, 'CmsBlockProvider');
    }

    handlePreloadEvent() {
        const { name } = this.props;

        if (hasCacheValue(name)) {
            this.setState({ blocks: getCacheValue(name) });
        } else {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        const { name, identifiers } = this.props;

        if (name && isPreloading()) {
            document.addEventListener(PRELOAD_FINISHED, this.handlePreloadEvent.bind(this));
        } else {
            this.fetchData(
                [CmsBlockQuery.getQuery({ identifiers })],
                ({ cmsBlocks: { items: blocks } }) => {
                    if (!blocks.length) {
                        return;
                    }

                    this.setState({ blocks });
                },
                (error) => {
                    if (Array.isArray(error)) {
                        this.setState({ blocks: error?.at(1)?.cmsBlocks?.items || [] });
                    } else {
                        this.setState({
                            blocks: [],
                        });
                    }
                }
            );
        }
    }

    componentWillUnmount() {
        document.removeEventListener(PRELOAD_FINISHED, this.handlePreloadEvent.bind(this));
    }

    render() {
        const { children, identifiers } = this.props;
        const { blocks } = this.state;

        return <CmsBlockContext.Provider value={{ blocks, identifiers }}>{children}</CmsBlockContext.Provider>;
    }
}

export default CmsBlockProviderContainer;
