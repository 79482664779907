import { PureComponent } from 'react';

import * as FB_EVENTS from './Pixel.config';
import {
    trackAddPaymentInfo,
    trackAddToCart,
    trackAddToWishlist,
    trackCompleteRegistration,
    trackContact,
    trackInitiateCheckout,
    trackPurchase,
    trackSearch,
    trackViewCategory,
    trackViewContent,
} from './Pixel.event';
import PixelObservable from './Pixel.observable';

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Container */
export class PixelContainer extends PureComponent {
    events = {
        [FB_EVENTS.FB_VIEW_CONTENT]: trackViewContent,
        [FB_EVENTS.FB_INITIATE_CHECKOUT]: trackInitiateCheckout,
        [FB_EVENTS.FB_PURCHASE]: trackPurchase,
        [FB_EVENTS.FB_ADD_TO_CART]: trackAddToCart,
        [FB_EVENTS.FB_ADD_TO_WISHLIST]: trackAddToWishlist,
        [FB_EVENTS.FB_VIEW_CATEGORY]: trackViewCategory,
        [FB_EVENTS.FB_COMPLETE_REGISTRATION]: trackCompleteRegistration,
        [FB_EVENTS.FB_ADD_PAYMENT_INFO]: trackAddPaymentInfo,
        [FB_EVENTS.FB_CONTACT]: trackContact,
        [FB_EVENTS.FB_SEARCH]: trackSearch,
    };

    componentDidMount() {
        this.initialSubscribe();
    }

    componentWillUnmount() {
        PixelObservable.unsubscribe(this.handleObservable.bind(this));
    }

    initialSubscribe() {
        if (!window.fbq) {
            console.warn('Facebook Pixel not loaded');
        } else {
            PixelObservable.subscribe(this.handleObservable.bind(this));
        }
    }

    async handleObservable(event, options) {
        const callback = this.events[event];

        if (!callback) {
            return;
        }

        await callback(options);
    }

    render() {
        return null;
    }
}

export default PixelContainer;
