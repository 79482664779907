import Field from '@scandipwa/scandipwa/src/component/Field';
import Loader from '@scandipwa/scandipwa/src/component/Loader';
import React, { PureComponent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import FIELD_TYPE from 'Component/Field/Field.config';
import { CAPTCHA_TOKEN } from './Recaptcha.config';

import './Recaptcha.style';

/** @namespace Advox/Recaptcha/Component/Recaptcha/Component/Recaptcha */
export class Recaptcha extends PureComponent {
    renderRecaptcha() {
        const {
            onChange,
            onError,
            setCaptchaRef,
            captchaSetup
        } = this.props;

        const { theme, badge, size, type, publicKey } = captchaSetup || {}

        return (
            <ReCAPTCHA
                sitekey={publicKey}
                theme={theme}
                size={size}
                badge={badge}
                type={type}
                onChange={onChange}
                onExpired={onError}
                ref={setCaptchaRef}
            />
        );
    }

    renderValidationInput() {
        const { captchaToken } = this.props;

        return (
            <Field
                attr={{
                    id: CAPTCHA_TOKEN,
                    name: CAPTCHA_TOKEN,
                    value: captchaToken,
                }}
                type={FIELD_TYPE.text}
                addRequiredTag={true}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true
                }}
                hidden
            />
        );
    }

    render() {
        const { isCaptchaEnabled, captchaSetup } = this.props;
        const { publicKey } = captchaSetup

        if (!isCaptchaEnabled) {
            return null;
        }

        if (
            !captchaSetup || (captchaSetup && !publicKey)
        ) {
            return <Loader isLoading />;
        }

        return (
            <div block="Recaptcha">
                {this.renderRecaptcha()}
                {this.renderValidationInput()}
            </div>
        );
    }
}

export default Recaptcha;
