import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { CART_URL } from 'Route/CartPage/CartPage.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { GIFT_CARDS_ORDER_SUCCESS_URL_FRAGMENT } from 'Route/GiftCardsOrderSuccess/GiftCardsOrderSuccess.config';
import { INVOICE_INFORMATION } from 'Route/InvoiceInformation/InvoiceInformation.config';
import { ACCOUNT_URL, ACCOUNT_WISHLIST_URL } from 'Route/MyAccount/MyAccount.config';
import {
    DEFAULT_HEADER_STATE,
    HeaderContainer as SourceHeaderContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/Header/Header.container';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { HEADER_HELP_OVERLAY } from '../HeaderHelp/HeaderHelp.config';
import {
    CART,
    CART_OVERLAY,
    CHECKOUT_ACCOUNT,
    CUSTOMER_ACCOUNT,
    CUSTOMER_SUB_ACCOUNT,
    MENU,
    SEARCH,
} from './Header.config';

export { DEFAULT_HEADER_STATE };

/** @namespace SwiatKsiazkiBasic/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    navigationStateHistory: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationStateHistory,
    wishlistItemsCount: state.WishlistReducer.productsInWishlistCount,
    customer: state.MyAccountReducer.customer,
});

/** @namespace SwiatKsiazkiBasic/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace SwiatKsiazkiBasic/Component/Header/Container */
export class HeaderContainer extends SourceHeaderContainer {
    __construct(props) {
        super.__construct(props);

        this.state = {
            prevPathname: '',
            searchCriteria: '',
            isClearEnabled: this.getIsClearEnabled(),
            showMyAccountLogin: false,
            shouldRenderHelpOverlay: false,
        };
    }

    containerProps() {
        const { customer, device, wishlistItemsCount } = this.props;
        const {
            location: { pathname },
        } = history;
        const isCheckout = pathname.includes(CHECKOUT_URL);
        const isCart = pathname.includes(CART_URL);
        const isGiftCardOrderSuccess =
            pathname.includes(GIFT_CARDS_ORDER_SUCCESS_URL_FRAGMENT) || pathname.includes(INVOICE_INFORMATION);
        const isOnMenu = pathname.includes(MENU) && (device.isMobile || device.isTablet);
        const { shouldRenderHelpOverlay } = this.state;

        return {
            ...super.containerProps(),
            shouldRenderHelpOverlay,
            isCheckout: isCheckout || isCart,
            isGiftCardOrderSuccess,
            wishlistItemsCount,
            isOnMenu,
            customer,
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        onMinicartButtonHover: this.onMinicartButtonHover.bind(this),
        closeMobileAccountForm: this.closeMobileAccountForm.bind(this),
        onHelpButtonHover: this.onHelpButtonHover.bind(this),
        hideHelpOverlay: this.hideHelpOverlay.bind(this),
        onWishlistButtonOnClick: this.onWishlistButtonOnClick.bind(this),
    };

    onBackButtonClick(e) {
        const {
            navigationStateHistory,
            navigationState: { onBackClick },
            goToPreviousNavigationState,
        } = this.props;

        this.setState({ searchCriteria: '' });

        if (window.activeMenuItemsStack?.length > 1 || navigationStateHistory?.length > 2) {
            goToPreviousNavigationState();
        }

        if (onBackClick) {
            onBackClick(e);
        }
    }

    onSearchOutsideClick() {
        const {
            goToPreviousNavigationState,
            navigationState: { name },
            device: { isMobile },
        } = this.props;

        if (isMobile) {
            return;
        }

        if (name === SEARCH) {
            this.hideSearchOverlay();
            goToPreviousNavigationState();
        }
    }

    onMinicartButtonClick() {
        history.push(appendWithStoreCode(`/${CART}`));
    }

    onMinicartButtonHover() {
        const {
            showOverlay,
            navigationState: { name },
            device,
        } = this.props;

        if (name === CART_OVERLAY) {
            return;
        }

        if (!device.isMobile) {
            this.setState({ shouldRenderCartOverlay: true });

            showOverlay(CART_OVERLAY);
        }
    }

    onHelpButtonHover() {
        const { showOverlay, activeOverlay, device } = this.props;

        if (activeOverlay === HEADER_HELP_OVERLAY) {
            return;
        }

        if (!device.isMobile) {
            this.setState((state) => ({ ...state, shouldRenderHelpOverlay: true }));
            showOverlay(HEADER_HELP_OVERLAY);
        }
    }

    hideHelpOverlay() {
        const { activeOverlay, hideActiveOverlay } = this.props;

        if (activeOverlay === HEADER_HELP_OVERLAY) {
            this.setState((state) => ({ ...state, shouldRenderHelpOverlay: false }));
            hideActiveOverlay();
        }
    }

    closeMobileAccountForm() {
        const { setNavigationState } = this.props;

        setNavigationState(this.routeMap['/']);
        history.push(appendWithStoreCode('/'));
    }

    onSearchBarFocus() {
        const {
            setNavigationState,
            goToPreviousNavigationState,
            showOverlay,
            navigationState: { name, title },
            device,
        } = this.props;

        if (!device.isMobile && name === SEARCH) {
            return;
        }

        showOverlay(SEARCH);
        setNavigationState({
            name: SEARCH,
            title: name === 'default' ? '' : title,
            onBackClick: () => {
                showOverlay(MENU);
                goToPreviousNavigationState();
            },
        });
    }

    onWishlistButtonOnClick() {
        const { showNotification } = this.props;

        if (isSignedIn()) {
            history.push({ pathname: appendWithStoreCode(ACCOUNT_WISHLIST_URL) });

            return;
        }

        showNotification('info', __('Please, sign in to access this page contents!'));
        this.onMyAccountButtonClick();
    }

    onMyAccountButtonClick() {
        const { showOverlay, setNavigationState } = this.props;

        if (isSignedIn()) {
            history.push({ pathname: appendWithStoreCode(ACCOUNT_URL) });

            return;
        }

        showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
        setNavigationState({
            name: CHECKOUT_ACCOUNT,
            title: 'Sign in',
        });
    }

    onMyAccountOutsideClick() {
        const {
            goToPreviousNavigationState,
            hideActiveOverlay,
            navigationState: { name },
            device,
        } = this.props;

        if (device.isMobile || ![CUSTOMER_ACCOUNT, CUSTOMER_SUB_ACCOUNT, CHECKOUT_ACCOUNT].includes(name)) {
            return;
        }

        if (name === CUSTOMER_SUB_ACCOUNT) {
            goToPreviousNavigationState();
        }

        this.goToDefaultHeaderState();
        hideActiveOverlay();
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
