import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { TERMS_AND_CONDITIONS_POPUP_ID } from 'Component/CheckoutTermsAndConditionsPopup/CheckoutTermsAndConditionsPopup.config';
import ConfigQuery from 'Query/Config.query';
import {
    clearAgreement,
    clearAgreements,
    loadAgreements,
    updateSelectAgreement,
} from 'Store/Agreements/Agreements.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import DataContainer from 'Util/Request/DataContainer';
import getStore from 'Util/Store';
import { validateGroup } from 'Util/Validator/Validator';

import Agreements from './Agreements.component';

/** @namespace SwiatKsiazkiBasic/Component/Agreements/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/Agreements/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateSelectAgreement: (name, agreement) => dispatch(updateSelectAgreement(name, agreement)),
    loadAgreements: (name, agreements) => dispatch(loadAgreements(name, agreements)),
    clearAgreement: (name) => dispatch(clearAgreement(name)),
    showPopup: (payload) => dispatch(showPopup(TERMS_AND_CONDITIONS_POPUP_ID, payload)),
    showNotification: (type, msg) => dispatch(showNotification(type, msg)),
});

/** @namespace SwiatKsiazkiBasic/Component/Agreements/Container */
export class AgreementsContainer extends DataContainer {
    static getArrangementSectionName(name) {
        return `${name}-agreement`;
    }

    static getArrangementFieldName(name, value) {
        return `${name}-agreement-${value}`;
    }

    static validateAgreements(name) {
        if (name in getStore().getState().AgreementsReducer) {
            try {
                return (
                    validateGroup(document.getElementById(AgreementsContainer.getArrangementSectionName(name)), {}) ===
                        true &&
                    (getStore().getState().AgreementsReducer[name].isValidated || false)
                );
            } catch {
                return getStore().getState().AgreementsReducer[name].isValidated || false;
            }
        }

        return true;
    }

    static getSelectedAgreements(name) {
        const selected = getStore()
            .getState()
            .AgreementsReducer?.[name]?.selected?.map((option) => option.agreement_id);

        return (
            getStore()
                .getState()
                .AgreementsReducer?.[name]?.all?.map((option) => ({
                    agreement_id: option.agreement_id,
                    checked: selected.includes(option.agreement_id),
                })) || []
        );
    }

    static clearAgreements() {
        return getStore().dispatch(clearAgreements());
    }

    static propTypes = {
        name: PropTypes.string.isRequired,
        updateSelectAgreement: PropTypes.func.isRequired,
        clearAgreement: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        isCheckout: PropTypes.bool,
        isVisible: PropTypes.bool,
        disabledClearOnUnmount: PropTypes.bool,
        disabledPopupOnClick: PropTypes.bool,
        section: PropTypes.string,
        pick: PropTypes.arrayOf(PropTypes.number),
        customErrorMessages: PropTypes.array,
        disabledLoader: PropTypes.bool,
        notificationOnError: PropTypes.bool,
        additional: PropTypes.object,
    };

    static defaultProps = {
        section: '',
        pick: [],
        isCheckout: false,
        isVisible: true,
        disabledClearOnUnmount: false,
        disabledPopupOnClick: false,
        disabledLoader: false,
        customErrorMessages: [],
        notificationOnError: false,
        additional: {},
    };

    state = {
        isLoading: true,
        agreements: [],
    };

    containerFunctions = {
        getArrangementFieldName: this.getArrangementFieldName.bind(this),
        getArrangementSectionName: this.getArrangementSectionName.bind(this),
        handleShowPopup: this.handleShowPopup.bind(this),
        handleSelectAgreement: this.handleSelectAgreement.bind(this),
        handleNotificationError: this.handleNotificationError.bind(this),
    };

    componentDidMount() {
        this.fetchAgreements();
    }

    componentDidUpdate(prevProps) {
        const { name, clearAgreement } = this.props;
        const { name: prevName } = prevProps;

        if (prevName !== name && !!name) {
            if (prevName) {
                clearAgreement(prevName);
            }
            this.fetchAgreements();
        }
    }

    componentWillUnmount() {
        const { name, disabledClearOnUnmount } = this.props;

        if (!disabledClearOnUnmount) {
            const { clearAgreement } = this.props;
            clearAgreement(name);
        }
    }

    __construct(props) {
        super.__construct(props, 'Agreements');
    }

    fetchAgreements() {
        const { name, section, loadAgreements } = this.props;

        if (!name) {
            throw new Error('Name is required');
        }

        this.fetchData(
            ConfigQuery.getCheckoutAgreements(section),
            ({ checkoutAgreements: data }) => {
                const agreements = this.getAgreements(data);

                loadAgreements(name, agreements);

                this.setState({
                    isLoading: false,
                    agreements,
                });
            },
            () => {
                this.setState({
                    isLoading: false,
                });
            }
        );
    }

    getAgreements(data) {
        const { pick, isCheckout } = this.props;

        if (isCheckout) {
            return data.filter((item) => item.show_in_checkout === isCheckout);
        }

        if (pick.length > 0) {
            return data.filter((item) => pick.includes(item.agreement_id));
        }

        return data;
    }

    getArrangementFieldName(value) {
        const { name } = this.props;

        return AgreementsContainer.getArrangementFieldName(name, value);
    }

    getArrangementSectionName() {
        const { name } = this.props;

        return AgreementsContainer.getArrangementSectionName(name);
    }

    handleSelectAgreement(agreement) {
        const { name, updateSelectAgreement } = this.props;

        updateSelectAgreement(name, agreement);
    }

    handleShowPopup(event, agreement) {
        const { showPopup, disabledPopupOnClick } = this.props;
        const { content, checkbox_text } = agreement;

        if (!disabledPopupOnClick) {
            if (!!content && checkbox_text !== content) {
                event.preventDefault();
                event.stopPropagation();

                showPopup({
                    text: content,
                });
            }
        }
    }

    handleNotificationError({ errorMessages }) {
        const { notificationOnError, showNotification } = this.props;

        if (notificationOnError && Array.isArray(errorMessages)) {
            showNotification('error', errorMessages[0]);
        }
    }

    containerProps() {
        const { name, isVisible, disabledPopupOnClick, customErrorMessages, disabledLoader, additional } = this.props;
        const { isLoading, agreements } = this.state;

        return {
            isLoading,
            isVisible,
            name,
            agreements,
            disabledPopupOnClick,
            disabledLoader,
            customErrorMessages,
            additional,
        };
    }

    render() {
        return <Agreements {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgreementsContainer);
