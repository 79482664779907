Mosaic.setPlugins([require('./plugin/CategoryReset.plugin.js'),
require('./plugin/CheckoutReducer.plugin.js'),
require('./plugin/MyAccountReducer.plugin.js'),
require('./plugin/OverlayContainer.plugin.js'),
require('./plugin/PopupComponent.plugin.js'),
require('./plugin/ProductListDispatcher.plugin.js'),
require('./plugin/ProductReset.plugin.js'),
require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/config/ConfigDispatcher.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/config/ConfigQuery.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/config/ConfigReducer.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/config/MyAccountQuery.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/config/ProductListQuery.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/cart.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/checkout.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/errorMessage.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/filter.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/general.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/list.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/notFound.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/pageReload.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/product.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/search.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/sort.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/user.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/wishlist.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/recaptcha/src/plugin/Config.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/recaptcha/src/plugin/Field.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/recaptcha/src/plugin/Request.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/blog/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/blog/src/plugin/HeaderComponent.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/blog/src/plugin/Image.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/blog/src/plugin/Router.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/blog/src/plugin/StoreIndex.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/slider/src/plugin/HtmlComponent.plugin.js')]);
import 'Util/Preload';

import(
    /* webpackMode: "lazy", webpackChunkName: "renderer" */
    './renderer'
);
