import { connect } from 'react-redux';

import { MENU_SUBCATEGORY } from 'Component/Header/Header.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuContainer as SourceMenuContainer,
} from 'SourceComponent/Menu/Menu.container';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { scrollToTop } from 'Util/Browser';
import history from 'Util/History';
import { isObjectEmpty } from 'Util/Object';

/** @namespace SwiatKsiazkiBasic/Component/Menu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState,
});

/** @namespace SwiatKsiazkiBasic/Component/Menu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setHeaderState: (headerState) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, headerState)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
});

/** @namespace SwiatKsiazkiBasic/Component/Menu/Container */
export class MenuContainer extends SourceMenuContainer {
    componentDidMount() {
        this._getMenu();

        window.addEventListener('changedStack', this.historyBackHook.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('changedStack', this.historyBackHook.bind(this));
    }

    __construct(props) {
        super.__construct(props, 'MenuContainer');

        const activeMenuItemsStack = window.activeMenuItemsStack || [];

        this.state = {
            activeMenuItemsStack,
            isNestedOpen: activeMenuItemsStack.length > 1,
            menu: {},
            isOverlayVisible: false,
        };
    }

    historyBackHook() {
        const { activeMenuItemsStack } = this.state;

        if (activeMenuItemsStack.length) {
            this.setState((prevState) => {
                const stack = prevState.activeMenuItemsStack.slice(1);
                window.activeMenuItemsStack = stack;
                return { activeMenuItemsStack: stack, isNestedOpen: stack.length > 1 };
            });
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        onCategoryBlockTitleClick: this.onCategoryBlockTitleClick.bind(this),
    };

    containerProps() {
        const { isOverlayVisible, isNestedOpen } = this.state;
        const { inHeader } = this.props;

        return {
            ...super.containerProps(),
            isOverlayVisible,
            isNestedOpen,
            inHeader,
        };
    }

    handleSubcategoryClick(e, activeSubcategory) {
        const { activeMenuItemsStack } = this.state;
        const { item_id, title, icon, url } = activeSubcategory;
        const { setHeaderState, navigationState, hideActiveOverlay } = this.props;

        e.stopPropagation();

        const newActiveMenuItemsStack = this.getNewActiveMenuItemsStack(activeMenuItemsStack, item_id);
        this.setState({
            activeMenuItemsStack: newActiveMenuItemsStack,
            isNestedOpen: newActiveMenuItemsStack?.length > 1,
        });

        scrollToTop();

        setHeaderState({
            name: MENU_SUBCATEGORY,
            title,
            titleIconBefore: icon,
            url: url || {},
            onBackClick: () => {
                setHeaderState(navigationState);
                window.dispatchEvent(new CustomEvent('changedStack'));
                scrollToTop();
            },
            onCloseClick: () => {
                hideActiveOverlay();
                this.setState({ activeMenuItemsStack: [], isNestedOpen: false });
                scrollToTop();
            },
        });
    }

    onCategoryHover(activeSubcategory) {
        const { device } = this.props;
        const { activeMenuItemsStack } = this.state;

        if (device.isMobile || device.isTablet) {
            return;
        }

        const { item_id, children } = activeSubcategory;

        if (!isObjectEmpty(children)) {
            this.setState({ isOverlayVisible: true });
        } else {
            this.setState({ isOverlayVisible: false });
        }

        if (activeMenuItemsStack.includes(item_id)) {
            return;
        }

        this.setState({ activeMenuItemsStack: [item_id] });
    }

    onCategoryBlockTitleClick(categoryUrl) {
        const { device } = this.props;

        if (device.isMobile || device.isTablet || !categoryUrl) {
            return;
        }

        scrollToTop();
        this.closeMenu();

        history.push(categoryUrl);
    }

    closeMenu() {
        const { device } = this.props;

        if (device.isMobile || device.isTablet) {
            return;
        }

        this.setState({ activeMenuItemsStack: [], isOverlayVisible: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
