export const DEFAULT_MAIN_SETTINGS = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    center: true,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};
