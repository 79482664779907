import { Children, PureComponent } from 'react';
import SlickSlider from 'react-slick';

import Html from 'Component/Html';
import Icon from 'Component/Icon';
import Link from 'Component/Link';
import { inRange } from 'Util/Date';
import media from 'Util/Media';

import { CmsBlock } from '../../route/Checkout/Checkout.component';
import { HOMEPAGE_MAIN_SLIDER_ADDITIONAL_TEXT_CMS_ID } from '../../route/HomePage/HomePage.config';
import { DEFAULT_BOTTOM_SETTINGS, DEFAULT_MAIN_SETTINGS, DEFAULT_MID_SETTINGS } from './HomeWidgets.config';

import './HomeWidgets.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/HomeWidgets/Component */
export class HomeWidgetsComponent extends PureComponent {
    renderAdditionalText(additionalText) {
        if (!additionalText || additionalText === '') {
            return <div key="test" />;
        }

        return (
            <div block="HomeWidgets" elem="Additional">
                <Html content={additionalText} />
            </div>
        );
    }

    renderNextArrow({ onClick, forBottom }) {
        return (
            <button
                block="HomeWidgets"
                elem="NavigationButton"
                mods={{ next: true, forBottom: !!forBottom }}
                type="button"
                onClick={onClick}
            >
                {forBottom ? (
                    <Icon name="slider_arrow_right" />
                ) : (
                    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20.5" r="20" fill="white" />
                        <path
                            opacity="0.5"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M23.0721 20.5067L16.8184 14.1664C15.9726 13.3297 17.3663 11.9 18.2121 12.7723L25.3234 19.9161C25.6331 20.236 25.6331 20.7527 25.3234 21.0357L18.2121 28.2459C17.3663 29.0826 15.9726 27.6553 16.8184 26.8186L23.0721 20.5067Z"
                            fill="black"
                        />
                    </svg>
                )}
            </button>
        );
    }

    renderPrevArrow({ onClick, forBottom }) {
        return (
            <button
                block="HomeWidgets"
                elem="NavigationButton"
                mods={{ prev: true, forBottom: !!forBottom }}
                type="button"
                onClick={onClick}
            >
                {forBottom ? (
                    <Icon name="slider_arrow_left" />
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={41} fill="none">
                        <g clipPath="url(#a)">
                            <path
                                fill="#fff"
                                d="M20 .5C8.954.5 0 9.454 0 20.5s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20Z"
                            />
                            <path
                                fill="#000"
                                fillRule="evenodd"
                                d="m16.928 20.493 6.254 6.34c.845.837-.548 2.267-1.394 1.395l-7.111-7.144c-.31-.32-.31-.837 0-1.12l7.11-7.21c.847-.837 2.24.59 1.395 1.427l-6.254 6.312Z"
                                clipRule="evenodd"
                                opacity={0.5}
                            />
                        </g>
                        <defs>
                            <clipPath id="a">
                                <path fill="#fff" d="M40 41H0V0h40z" />
                            </clipPath>
                        </defs>
                    </svg>
                )}
            </button>
        );
    }

    renderSlide(options, slide) {
        const { header, image, tablet_image: tabletImage, mobile_image: mobileImage, cta_link: ctaLink } = slide;

        const bottomTitle = options?.withBottomTitle ? (
            <h4 block="HomeWidgets" elem="BottomTitle">
                {header}
            </h4>
        ) : null;
        const wrapper = (children) => (
            <div block="HomeWidgets" elem="MainSlideWrapper">
                {children}
            </div>
        );

        if (ctaLink) {
            return wrapper(
                <div block="HomeWidgets" elem="MainSlide" mods={{ withBottomTitle: !!bottomTitle }}>
                    <Link block="HomeWidgets" elem="MainSlideContent" to={ctaLink}>
                        <picture block="HomeWidgets" elem="Image">
                            {mobileImage ? <source media="(max-width: 767px)" srcSet={media(mobileImage)} /> : null}
                            {tabletImage ? <source media="(max-width: 1023px)" srcSet={media(tabletImage)} /> : null}
                            <img src={media(image)} alt={header} />
                        </picture>
                        {bottomTitle}
                    </Link>
                </div>
            );
        }

        return wrapper(
            <div block="HomeWidgets" elem="MainSlide" mods={{ withBottomTitle: !!bottomTitle }}>
                <div block="HomeWidgets" elem="MainSlideContent">
                    <picture block="HomeWidgets" elem="Image">
                        {mobileImage ? <source media="(max-width: 767px)" srcSet={media(mobileImage)} /> : null}
                        {tabletImage ? <source media="(max-width: 1023px)" srcSet={media(tabletImage)} /> : null}
                        <img src={media(image)} alt={header} />
                    </picture>
                    {bottomTitle}
                </div>
            </div>
        );
    }

    renderSlider(children, settings = {}) {
        return <SlickSlider {...settings}>{children}</SlickSlider>;
    }

    renderMain() {
        const { mainSlider, device } = this.props;

        const slides = mainSlider?.slides?.filter(({ date_from, date_to }) => inRange(date_from, date_to));

        const additionalText = <CmsBlock identifier={HOMEPAGE_MAIN_SLIDER_ADDITIONAL_TEXT_CMS_ID} />;

        if (!slides?.length) {
            return null;
        }

        const NextArrow = this.renderNextArrow.bind(this);
        const PrevArrow = this.renderPrevArrow.bind(this);

        return (
            <div block="HomeWidgets" elem="Main" mods={{ hasAdditionalText: Children.count(additionalText) > 0 }}>
                {this.renderSlider(slides?.map(this.renderSlide.bind(this, {})), {
                    ...DEFAULT_MAIN_SETTINGS,
                    infinite: slides.length > DEFAULT_MAIN_SETTINGS.slidesToShow,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                    autoplay: !!mainSlider?.autorotate_time,
                    autoplaySpeed: (Number(mainSlider?.autorotate_time) || 1) * 1000,
                })}
                {!device?.isMobile ? additionalText : null}
            </div>
        );
    }

    renderMid() {
        const { midSlider } = this.props;

        const slides = midSlider?.slides?.filter(({ date_from, date_to }) => inRange(date_from, date_to));

        if (!slides?.length) {
            return null;
        }

        const NextArrow = this.renderNextArrow.bind(this);
        const PrevArrow = this.renderPrevArrow.bind(this);

        return (
            <div block="HomeWidgets" elem="Mid">
                {this.renderSlider(slides?.map(this.renderSlide.bind(this, {})), {
                    ...DEFAULT_MID_SETTINGS,
                    infinite: slides.length > DEFAULT_MID_SETTINGS.slidesToShow,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                    autoplay: !!midSlider?.autorotate_time,
                    autoplaySpeed: (Number(midSlider?.autorotate_time) || 1) * 1000,
                })}
            </div>
        );
    }

    renderBottom() {
        const { bottomSlider, device } = this.props;

        const slides = bottomSlider?.slides?.filter(({ date_from, date_to }) => inRange(date_from, date_to));

        if (!slides?.length) {
            return null;
        }

        const NextArrow = this.renderNextArrow.bind(this);
        const PrevArrow = this.renderPrevArrow.bind(this);

        return (
            <div block="HomeWidgets" elem="Bottom">
                {this.renderSlider(slides?.map(this.renderSlide.bind(this, { withBottomTitle: true })), {
                    ...DEFAULT_BOTTOM_SETTINGS,
                    infinite: !device?.isMobile ? slides.length > DEFAULT_BOTTOM_SETTINGS.slidesToShow : false,
                    nextArrow: <NextArrow forBottom />,
                    prevArrow: <PrevArrow forBottom />,
                    autoplay: !device?.isMobile ? !!bottomSlider?.autorotate_time : false,
                    autoplaySpeed: (Number(bottomSlider?.autorotate_time) || 1) * 1000,
                })}
            </div>
        );
    }

    render() {
        return (
            <div block="HomeWidgets">
                {this.renderMain()}
                {this.renderMid()}
                {this.renderBottom()}
            </div>
        );
    }
}

export default HomeWidgetsComponent;
