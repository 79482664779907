import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutSteps from './CheckoutSteps.component';

/** @namespace SwiatKsiazkiBasic/Component/CheckoutSteps/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    totals: state.CartReducer.cartTotals,
    customer: state.MyAccountReducer.customer,
});

/** @namespace SwiatKsiazkiBasic/Component/CheckoutSteps/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/CheckoutSteps/Container */
export class CheckoutStepsContainer extends PureComponent {
    static propTypes = {
        checkoutStep: PropTypes.string.isRequired,
    };

    containerProps = () => {
        const { checkoutStep, device, totals, customer: { is_bookstore = false } = {} } = this.props;

        return {
            checkoutStep,
            device,
            totals,
            isBookstore: is_bookstore,
        };
    };

    render() {
        return <CheckoutSteps {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStepsContainer);
