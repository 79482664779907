import RouteQuery from 'Query/Route.query';
import { TYPE_CUSTOM } from 'Route/UrlRewrites/UrlRewrites.config';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { setIsUrlRewritesLoading, updateUrlRewrite } from 'Store/UrlRewrites/UrlRewrites.action';

/**
 * Url Rewrite Dispathcer
 * @class UrlRewritesDispatcher
 * @extends RequestDispatcher
 * @namespace SwiatKsiazkiBasic/Store/UrlRewrites/Dispatcher */
export class UrlRewritesDispatcher {
    onSuccess({ route }, dispatch, { urlParam }) {
        if (route) {
            dispatch(updateUrlRewrite(route, urlParam));
        } else {
            dispatch(updateUrlRewrite({ notFound: true, type: TYPE_CUSTOM }, urlParam));
        }
        dispatch(updateNoMatch(!route));
    }

    onError(error, dispatch, { urlParam }) {
        dispatch(setIsUrlRewritesLoading(false));
        dispatch(updateUrlRewrite({ notFound: true, type: TYPE_CUSTOM }, urlParam));
        dispatch(updateNoMatch(true));
        dispatch(showNotification('error', __('Error fetching URL-rewrites!'), error));
    }

    /**
     * Prepare UrlRewrite requests
     * @param {Object} options A object containing different aspects of query, each item can be omitted
     * @return {Query} UrlRewrite query
     * @memberof UrlRewritesDispatcher
     */
    prepareRequest(options, dispatch) {
        dispatch(setIsUrlRewritesLoading(true));

        return RouteQuery.getQuery(this.processUrlOptions(options));
    }

    processUrlOptions(options) {
        const { urlParam } = options;

        // FAILSAFE: Trim index.php if someone forgot to set "Use Web Server Rewrites" to "Yes"
        const trimmedParam = urlParam.replace('index.php/', '');

        return {
            ...options,
            urlParam: encodeURIComponent(trimmedParam.replace(new RegExp(window.storeRegexText), '')),
        };
    }
}

export default new UrlRewritesDispatcher();
