/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import TagManager from 'react-gtm-module';

import { Cookie } from 'Util/Cookie';
import getStore from 'Util/Store';

import { checkIsEventEnable } from './checkIsEventEnable';
import { injectAntiFlicker, injectGoogleOptimize } from './googleOptimize';
import { waitForCallback } from './wait';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const beforeInitQue = [];

Cookie.onAcceptedCookie('statistic', async () => {
    await waitForCallback(() => getStore().getState()?.ConfigReducer?.gtm?.enabled);

    const { enabled: isEnabled } = getStore().getState()?.ConfigReducer?.gtm || {};

    if (isEnabled === undefined || isEnabled === false) {
        return;
    }

    if (isEnabled === false) {
        injectGoogleOptimize();
        injectAntiFlicker();

        return;
    }

    beforeInitQue.forEach((qData) => {
        if ('ecommerce' in qData) {
            TagManager.dataLayer({
                dataLayer: { ecommerce: null }
            });
        }

        TagManager.dataLayer({
            dataLayer: qData,
        });
    });

    beforeInitQue.splice(0, beforeInitQue.length);
});

/** @namespace SwiatKsiazkiBasic/Gtm/Util/Push/pushToDataLayer */
export const pushToDataLayer = async (data) => {
    await waitForCallback(() => getStore().getState().ConfigReducer?.gtm?.enabled);

    beforeInitQue.push(data);

    const { enabled: isEnabled } = getStore().getState()?.ConfigReducer?.gtm || {};

    if (isEnabled === undefined || isEnabled === false) {
        return;
    }

    if (isEnabled === false) {
        injectGoogleOptimize();
        injectAntiFlicker();

        return;
    }

    const { event } = data;

    if (!checkIsEventEnable(event) || !Cookie.canRunScript('statistic')) {
        return;
    }

    beforeInitQue.pop();

    if ('ecommerce' in data) {
        TagManager.dataLayer({
            dataLayer: { ecommerce: null }
        });
    }

    TagManager.dataLayer({
        dataLayer: data
    });
};
