import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import { BILLING_STEP, DETAILS_STEP, SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { DeviceType } from 'Type/Device.type';
import { getProductQuantityIsNotAvailable } from 'Util/Product/Extract';

import { CART_STEP, CART_STEP_URL, CHECKOUT_SHIPPING_STEP_URL } from './CheckoutSteps.config';

import './CheckoutSteps.style';

/** @namespace SwiatKsiazkiBasic/Component/CheckoutSteps/Component */
export class CheckoutSteps extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        checkoutStep: PropTypes.oneOf([CART_STEP, SHIPPING_STEP, BILLING_STEP, DETAILS_STEP]).isRequired,
    };

    checkoutSteps = [
        {
            name: CART_STEP,
            title: __('Cart'),
            link: CART_STEP_URL,
        },
        {
            name: SHIPPING_STEP,
            title: __('Delivery'),
            link: CHECKOUT_SHIPPING_STEP_URL,
        },
        {
            name: BILLING_STEP,
            title: __('Summary'),
            // we have to update checkout state with onClick fn instead of a link
            link: null,
        },
    ];

    renderCheckoutStepTitle(title) {
        const {
            device: { isMobile },
        } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <span block="CheckoutSteps" elem="StepTitle">
                {title}
            </span>
        );
    }

    goToBillingStep() {
        const stepForm = document.getElementById(SHIPPING_STEP);

        // before redirect to billing make sure shipping is completed
        if (stepForm) {
            const submitBtn = stepForm.querySelector('button[type="submit"]');

            return submitBtn && submitBtn.click();
        }
    }

    hasQuantityIsNotAvailableInCart(items) {
        return items.some(({ product, qty }) => getProductQuantityIsNotAvailable(product, qty));
    }

    renderCheckoutStep(item) {
        const {
            checkoutStep,
            totals: { items, is_virtual, items_qty = 0 },
            isBookstore,
        } = this.props;
        const isCartEmpty = items_qty === 0;
        const stepAvailable = checkoutStep && this.checkoutSteps.some((step) => step.name === checkoutStep);

        if (stepAvailable) {
            const { link, name, title } = item || {};
            const isShippingStepDisabled = (is_virtual || isBookstore) && name === SHIPPING_STEP;

            if (isShippingStepDisabled) {
                return null;
            }

            const ShippingTag =
                (name === SHIPPING_STEP && this.hasQuantityIsNotAvailableInCart(items) && 'div') || Link;
            const BillingTag =
                (name === BILLING_STEP && this.hasQuantityIsNotAvailableInCart(items) && 'div') || 'button';

            return (
                <div block="CheckoutSteps" elem="Step" mods={{ active: checkoutStep === name }} key={name}>
                    {isCartEmpty ? (
                        <div>
                            <span block="CheckoutSteps" elem="StepCount" />
                            {this.renderCheckoutStepTitle(title)}
                        </div>
                    ) : link ? (
                        <ShippingTag to={link}>
                            <span block="CheckoutSteps" elem="StepCount" />
                            {this.renderCheckoutStepTitle(title)}
                        </ShippingTag>
                    ) : (
                        <BillingTag
                            onClick={
                                name === BILLING_STEP &&
                                !this.hasQuantityIsNotAvailableInCart(items) &&
                                this.goToBillingStep
                            }
                        >
                            <span block="CheckoutSteps" elem="StepCount" />
                            {this.renderCheckoutStepTitle(title)}
                        </BillingTag>
                    )}
                </div>
            );
        }

        return null;
    }

    render() {
        const { checkoutStep } = this.props;

        if (checkoutStep === DETAILS_STEP) {
            return null;
        }

        return <div block="CheckoutSteps">{this.checkoutSteps.map((item) => this.renderCheckoutStep(item))}</div>;
    }
}

export default CheckoutSteps;
