import currencyMap from 'SourceUtil/Price/Price.config';
import { getDefaultCurrency } from 'Util/Currency';

export * from 'SourceUtil/Price/Price';

/** @namespace SwiatKsiazkiBasic/Util/Price/formatCurrency */
export const formatCurrency = (currency) => currencyMap[currency || getDefaultCurrency()];

/** @namespace SwiatKsiazkiBasic/Util/Price/formatPrice */
export const formatPrice = (price, currency) =>
    new Intl.NumberFormat(window.defaultLocale.split('_')[0], {
        style: 'currency',
        currency: currency || getDefaultCurrency(),
    }).format(price);
