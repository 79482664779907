import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock/CmsBlock.component';
import withCmsBlockProvider from 'Component/CmsBlockProvider/CmsBlockProvider.hoc';
import CmsBlockQuery from 'Query/CmsBlock.query';
import { getCacheValue, hasCacheValue, setCacheValue } from 'Util/Cache';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';

/** @namespace SwiatKsiazkiBasic/Component/CmsBlock/Container */
export class CmsBlockContainer extends PureComponent {
    static propTypes = {
        identifier: PropTypes.string.isRequired,
        cached: PropTypes.bool,
    };

    static defaultProps = {
        cached: false,
    };

    state = {
        cmsBlock: {},
    };

    componentDidMount() {
        this._getCmsBlock();
    }

    componentDidUpdate(prevProps) {
        const { blocks, identifier } = this.props;
        const { blocks: prevBlocks, identifier: prevIdentifier } = prevProps;

        if (prevBlocks && blocks && prevBlocks.length !== blocks.length) {
            this._getCmsBlock();
        }

        if (identifier !== prevIdentifier) {
            this._getCmsBlock();
        }
    }

    containerProps() {
        const { blockType } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType };
    }

    async _getCmsBlock() {
        const { identifier, cached, shared, hasIdentifier, getBlock } = this.props;

        if (shared && hasIdentifier(identifier)) {
            this.setState({ cmsBlock: getBlock(identifier) });
        } else if (cached && hasCacheValue(identifier)) {
            this.setState({ cmsBlock: getCacheValue(identifier)[0] });
        } else {
            const { cmsBlocks } = await executeGet(
                prepareQuery(CmsBlockQuery.getQuery({ identifiers: [identifier] })),
                'CmsBlock'
            );

            const { items } = cmsBlocks;

            if (!items.length) {
                return;
            }

            if (cached) {
                setCacheValue(identifier, items);
            }

            this.setState({ cmsBlock: items[0] });
        }
    }

    render() {
        return <CmsBlock {...this.containerProps()} />;
    }
}

export default withCmsBlockProvider(CmsBlockContainer);
