/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */

import { captureGraphqlException } from 'util/Request/Error';

import { getAuthorizationToken, isSignedIn, refreshAuthorizationToken } from 'Util/Auth';
import { refreshUid } from 'Util/Compare';
import { getCurrency } from 'Util/Currency';
import { hash } from 'Util/Request/Hash';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export const GRAPHQL_URI = '/graphql';
export const WINDOW_ID = 'WINDOW_ID';

export const GRAPHQL_AUTHENTICATION = 'graphql-authentication';
export const GRAPHQL_AUTHORIZATION = 'graphql-authorization';
export const GRAPHQL_NO_SUCH_ENTITY = 'graphql-no-such-entity';

export const HTTP_503_SERVICE_UNAVAILABLE = 503;
export const HTTP_410_GONE = 410;
export const HTTP_201_CREATED = 201;

/** @namespace SwiatKsiazkiBasic/Util/Request/getWindowId */
export const getWindowId = () => {
    const result = sessionStorage.getItem(WINDOW_ID);

    if (!result) {
        const id = Date.now();
        sessionStorage.setItem(WINDOW_ID, id);

        return id;
    }

    return result;
};

/** @namespace SwiatKsiazkiBasic/Util/Request/getStoreCodePath */
export const getStoreCodePath = () => {
    const path = location.pathname;
    // eslint-disable-next-line no-undef
    const firstPathPart = path.split('/')[1];

    if (window.storeList.includes(firstPathPart)) {
        return `/${firstPathPart}`;
    }

    return '';
};

/** @namespace SwiatKsiazkiBasic/Util/Request/getGraphqlEndpoint */
export const getGraphqlEndpoint = () => getStoreCodePath().concat(GRAPHQL_URI);

/** @namespace SwiatKsiazkiBasic/Util/Request/appendTokenToHeaders */
export const appendTokenToHeaders = (headers) => {
    const token = getAuthorizationToken();

    return {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
    };
};

/** @namespace SwiatKsiazkiBasic/Util/Request/formatURI */
export const formatURI = (query, variables, url) => {
    // eslint-disable-next-line no-param-reassign
    variables._currency = getCurrency();

    const stringifyVariables = Object.keys(variables).reduce(
        (acc, variable) => [...acc, `${variable}=${JSON.stringify(variables[variable])}`],
        [`?hash=${hash(query)}`]
    );

    return `${url}${stringifyVariables.join('&')}`;
};

/** @namespace SwiatKsiazkiBasic/Util/Request/getFetch */
export const getFetch = (uri, name) =>
    fetch(uri, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Application-Model': `${name}_${getWindowId()}`,
            Accept: 'application/json',
        },
    });

/** @namespace SwiatKsiazkiBasic/Util/Request/putPersistedQuery */
export const putPersistedQuery = (graphQlURI, query, cacheTTL) =>
    fetch(`${graphQlURI}?hash=${hash(query)}`, {
        method: 'PUT',
        body: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json',
            'SW-Cache-Age': Number.isInteger(cacheTTL) ? cacheTTL : ONE_MONTH_IN_SECONDS,
        },
    });

/** @namespace SwiatKsiazkiBasic/Util/Request/postFetch */
export const postFetch = (graphQlURI, query, variables) =>
    fetch(graphQlURI, {
        method: 'POST',
        body: JSON.stringify({ query, variables }),
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }),
    });

/** @namespace SwiatKsiazkiBasic/Util/Request/checkForErrors */
export const checkForErrors = (res, options) =>
    new Promise((resolve, reject) => {
        const { errors, data } = res;

        if (errors) {
            if (Array.isArray(errors)) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return reject([...errors, data]);
            }

            if (options) {
                captureGraphqlException(errors, options);
            }

            return reject(errors);
        }

        return resolve(data);
    });

/** @namespace SwiatKsiazkiBasic/Util/Request/handleConnectionError */
export const handleConnectionError = (err, msg) => {
    // eslint-disable-next-line no-console
    console.error(msg, err);
};

/** @namespace SwiatKsiazkiBasic/Util/Request/parseResponse */
export const parseResponse = (promise, options) =>
    new Promise((resolve, reject) => {
        promise.then(
            /** @namespace SwiatKsiazkiBasic/Util/Request/parseResponse/Promise/promise/then */
            (res) =>
                res.json().then(
                    /** @namespace SwiatKsiazkiBasic/Util/Request/parseResponse/Promise/promise/then/json/then/resolve */
                    (res) => resolve(checkForErrors(res, options)),
                    /** @namespace SwiatKsiazkiBasic/Util/Request/parseResponse/Promise/promise/then/json/then/catch */
                    (err) => {
                        captureGraphqlException('Can not parse JSON!', options);
                        handleConnectionError(err, 'Can not transform JSON!');
                        return reject(err);
                    }
                ),
            /** @namespace SwiatKsiazkiBasic/Util/Request/parseResponse/Promise/promise/then/catch */
            (err) => {
                captureGraphqlException('Can not establish connection!', options);
                handleConnectionError('Can not establish connection!');
                return reject(err);
            }
        );
    });

/** @namespace SwiatKsiazkiBasic/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    if (isSignedIn()) {
        refreshAuthorizationToken();
        refreshUid();
    }

    return parseResponse(
        new Promise((resolve, reject) => {
            getFetch(uri, name).then(
                /** @namespace SwiatKsiazkiBasic/Util/Request/executeGet/parseResponse/getFetch/then */
                (res) => {
                    if (res.status === HTTP_410_GONE) {
                        putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                            /** @namespace SwiatKsiazkiBasic/Util/Request/executeGet/parseResponse/getFetch/then/putPersistedQuery/then */
                            (putResponse) => {
                                if (putResponse.status === HTTP_201_CREATED) {
                                    getFetch(uri, name).then(
                                        /** @namespace SwiatKsiazkiBasic/Util/Request/executeGet/parseResponse/getFetch/then/putPersistedQuery/then/getFetch/then/resolve */
                                        (res) => resolve(res)
                                    );
                                }
                            }
                        );
                    } else if (res.status === HTTP_503_SERVICE_UNAVAILABLE) {
                        reject(res);
                    } else {
                        resolve(res);
                    }
                }
            );
        }),
        queryObject
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Request/executePost */
export const executePost = (queryObject) => {
    const { query, variables } = queryObject;

    if (isSignedIn()) {
        refreshAuthorizationToken();
        refreshUid();
    }

    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables), queryObject);
};

/** @namespace SwiatKsiazkiBasic/Util/Request/executePreload */
export const executePreload = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    return parseResponse(
        new Promise((resolve, reject) => {
            getFetch(uri, name).then(
                /** @namespace SwiatKsiazkiBasic/Util/Request/executePreload/parseResponse/getFetch/then */
                (res) => {
                    if (res.status === HTTP_410_GONE) {
                        putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                            /** @namespace SwiatKsiazkiBasic/Util/Request/executePreload/parseResponse/getFetch/then/putPersistedQuery/then */
                            (putResponse) => {
                                if (putResponse.status === HTTP_201_CREATED) {
                                    getFetch(uri, name).then(
                                        /** @namespace SwiatKsiazkiBasic/Util/Request/executePreload/parseResponse/getFetch/then/putPersistedQuery/then/getFetch/then/resolve */
                                        (res) => resolve(res)
                                    );
                                }
                            }
                        );
                    } else if (res.status === HTTP_503_SERVICE_UNAVAILABLE) {
                        reject(res);
                    } else {
                        resolve(res);
                    }
                }
            );
        })
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Request/listenForBroadCast */
export const listenForBroadCast = (name) =>
    new Promise((resolve) => {
        const { BroadcastChannel } = window;
        const windowId = getWindowId();

        if (BroadcastChannel) {
            const bc = new BroadcastChannel(`${name}_${windowId}`);
            bc.onmessage = (update) => {
                const {
                    data: { payload: body },
                } = update;

                resolve(checkForErrors(body));
            };
        }
    });

/** @namespace SwiatKsiazkiBasic/Util/Request/debounce */
export const debounce = (callback, delay) => {
    // eslint-disable-next-line fp/no-let
    let timeout;

    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), delay);
    };
};

/** @namespace SwiatKsiazkiBasic/Util/Request */
export class Debouncer {
    timeout;

    handler = () => {};

    startDebounce =
        (callback, delay) =>
        (...args) => {
            const context = this;
            clearTimeout(this.timeout);
            this.handler = () => callback.apply(context, args);
            this.timeout = setTimeout(this.handler, delay);
        };

    cancelDebounce = () => {
        clearTimeout(this.timeout);
    };

    cancelDebounceAndExecuteImmediately = () => {
        clearTimeout(this.timeout);
        this.handler();
    };
}

/** @namespace SwiatKsiazkiBasic/Util/Request/runBeforeTimeout */
export const runBeforeTimeout = (func, timeout) => {
    let run = true;

    const timer = setTimeout(() => {
        run = false;
    }, timeout);

    return (...args) => {
        clearTimeout(timer);

        if (run) {
            func.apply(this, ...args);
        }
    };
};
