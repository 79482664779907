export const DEFAULT_SLICK_SETTINGS = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    appendDots: (dots) => <ul>{dots}</ul>,
    arrows: true,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                arrows: false,
            },
        },
    ],
};

export const HOMEPAGE_POSITION = 'homepage';

export const TRUNCATED_LAYOUT = 'Truncated';
