import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import Html from 'Component/Html';
import Link from 'Component/Link';
import { inRange } from 'Util/Date';
import media from 'Util/Media';

import { DEFAULT_MAIN_SETTINGS } from './TopBarWidget.config';

import './TopBarWidget.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/TopBarWidget/Component */
export class TopBarWidgetComponent extends PureComponent {
    componentDidMount() {
        const { mainSlider } = this.props;

        const slides = mainSlider?.slides?.filter(({ date_from, date_to }) => inRange(date_from, date_to));

        if (!slides?.length) {
            document.documentElement.classList.remove('topbar');
        } else {
            document.documentElement.classList.add('topbar');
        }
    }

    componentDidUpdate() {
        const { mainSlider } = this.props;

        const slides = mainSlider?.slides?.filter(({ date_from, date_to }) => inRange(date_from, date_to));

        if (!slides?.length) {
            document.documentElement.classList.remove('topbar');
        } else {
            document.documentElement.classList.add('topbar');
        }
    }

    renderAdditionalText(additionalText) {
        if (!additionalText || additionalText === '') {
            return <div />;
        }

        return (
            <div block="TopBarWidget" elem="Additional">
                <Html content={additionalText} />
            </div>
        );
    }

    renderSlide(slide) {
        const { header, image, cta_link: ctaLink, additional_text: additionalText } = slide;

        const additionalTextRender = this.renderAdditionalText(additionalText);

        const wrapper = (children) => <div>{children}</div>;

        if (ctaLink) {
            return wrapper(
                <div block="TopBarWidget" elem="MainSlide">
                    <Link to={ctaLink}>
                        <img src={media(image)} alt={header} />
                        {additionalTextRender}
                    </Link>
                </div>
            );
        }

        return wrapper(
            <div block="TopBarWidget" elem="MainSlide">
                <div>
                    <img src={media(image)} alt={header} />
                    {additionalTextRender}
                </div>
            </div>
        );
    }

    renderSlider(children, settings = {}) {
        return <SlickSlider {...settings}>{children}</SlickSlider>;
    }

    renderMain() {
        const { mainSlider } = this.props;

        const slides = mainSlider?.slides?.filter(({ date_from, date_to }) => inRange(date_from, date_to));

        if (!slides?.length) {
            return null;
        }

        return (
            <div block="TopBarWidget">
                <div block="TopBarWidget" elem="Container">
                    <div block="TopBarWidget" elem="Main">
                        {this.renderSlider(slides?.map(this.renderSlide.bind(this)), {
                            ...DEFAULT_MAIN_SETTINGS,
                            infinite: slides.length >= DEFAULT_MAIN_SETTINGS.slidesToShow,
                            autoplay: !!mainSlider?.autorotate_time,
                            autoplaySpeed: (Number(mainSlider?.autorotate_time) || 1) * 1000,
                        })}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return this.renderMain();
    }
}

export default TopBarWidgetComponent;
