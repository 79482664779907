export * from 'SourceUtil/Address';

/** @namespace SwiatKsiazkiBasic/Util/Address/Index/trimCustomerAddress */
export const trimCustomerAddress = (customerAddress) => {
    const {
        default_shipping = false,
        default_billing = false,
        company = null,
        city = '',
        country_id = 1,
        firstname = '',
        lastname = '',
        middlename = '',
        postcode = '',
        street = [''],
        telephone = '',
        region: { region_code = null, region = null, region_id = 1 } = {},
        prefix = '',
        suffix = '',
        vat_id = null,
        address_name = '',
    } = customerAddress;

    return {
        company,
        default_shipping,
        default_billing,
        city,
        country_id,
        firstname,
        lastname,
        middlename,
        postcode,
        street,
        telephone,
        region: {
            region_code,
            region,
            region_id,
        },
        prefix,
        suffix,
        vat_id,
        custom_attributes: [
            {
                attribute_code: 'address_name',
                value: address_name,
            },
        ],
    };
};

/** @namespace SwiatKsiazkiBasic/Util/Address/Index/trimCheckoutAddress */
export const trimCheckoutAddress = (customerAddress) => {
    const {
        company = null,
        city = '',
        country_id = 1,
        firstname = '',
        lastname = '',
        postcode = '',
        street = [''],
        telephone = '',
        region_string = '',
        region_id = 0,
        region_code = null,
        vat_id = null,
        email = '',
    } = customerAddress;

    return {
        company,
        city,
        country_id,
        firstname,
        lastname,
        postcode,
        street,
        telephone,
        region: region_string,
        region_id: region_id === '' ? 0 : region_id,
        region_code,
        vat_id,
        ...(email ? { email } : null),
    };
};

/** @namespace SwiatKsiazkiBasic/Util/Address/Index/sortCountries */
export const sortCountries = (countries) => {
    const sortedCountries = Array.from(countries);
    sortedCountries.unshift(
        sortedCountries.splice(
            sortedCountries.findIndex((country) => country.id === 'PL'),
            1
        )[0]
    );

    return sortedCountries;
};

/** @namespace SwiatKsiazkiBasic/Util/Address/Index/getIsFullAddress */
export const getIsFullAddress = (address) => {
    const { postcode, street, city } = address || {};

    return !!(postcode && street && city && street?.length && street[0] !== '');
};

/** @namespace SwiatKsiazkiBasic/Util/Address/Index/getSortedAddressesByDefault */
export const getSortedAddressesByDefault = (addresses, variant = 'shipping') => {
    const currentDefault = variant === 'shipping' ? 'default_shipping' : 'default_billing';
    const arrayOfDefaultAddresses = addresses
        .filter((address) => address.default_shipping || address.default_billing)
        .sort((address) => {
            if (address[currentDefault]) {
                return -1;
            }

            return 1;
        });

    const arrayOfOtherAddresses = addresses.filter((address) => !address.default_shipping && !address.default_billing);

    return [...arrayOfDefaultAddresses, ...arrayOfOtherAddresses];
};

/** @namespace SwiatKsiazkiBasic/Util/Address/Index/getOnlyFullAddresses */
export const getOnlyFullAddresses = (addresses) => addresses.filter((address) => getIsFullAddress(address));

/** @namespace SwiatKsiazkiBasic/Util/Address/Index/getUniqueAddresses */
export const getUniqueAddresses = (addresses) => {
    const uniqueAddresses = addresses.reduce((unique, current) => {
        if (
            !unique.some(
                (obj) =>
                    obj.firstname === current.firstname &&
                    obj.lastname === current.lastname &&
                    obj.telephone === current.telephone
            )
        ) {
            unique.push(current);
        }

        return unique;
    }, []);

    return uniqueAddresses;
};

/** @namespace SwiatKsiazkiBasic/Util/Address/Index/getDefaultAddressLabel */
export const getDefaultAddressLabel = (address) => {
    const { default_billing, default_shipping } = address;

    if (!default_billing && !default_shipping) {
        return '';
    }

    if (default_billing && default_shipping) {
        return __('(default shipping & billing)');
    }

    if (default_billing) {
        return __('(default billing address)');
    }

    return __('(default shipping address)');
};
