import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UrlRewritesDispatcher from 'Store/UrlRewrites/UrlRewrites.dispatcher';
import { HistoryType, LocationType, MatchType, UrlRewriteType } from 'Type/Router.type';
import { getCacheValue, hasCacheValue } from 'Util/Cache';
import { isPreloadingRoute, PRELOAD_KEYS, PRELOAD_ROUTE_FINISHED } from 'Util/Preload';
import DataContainer from 'Util/Request/DataContainer';
import { isHomePageUrl } from 'Util/Url';

import UrlRewrites from './UrlRewrites.component';
import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_CUSTOM,
    TYPE_DICTIONARY,
    TYPE_NOTFOUND,
    TYPE_PRODUCT,
} from './UrlRewrites.config';

export const NoMatchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NoMatch/NoMatch.dispatcher'
);

/** @namespace SwiatKsiazkiBasic/Route/UrlRewrites/Container/mapStateToProps */
export const mapStateToProps = (state, { location: { pathname } }) => ({
    pathname: isHomePageUrl(pathname)
        ? state.ConfigReducer.cms_home_page ?? window.storeConfig.cms_home_page
        : pathname,
    urlRewrite: state.UrlRewritesReducer.urlRewrite,
    isLoading: state.UrlRewritesReducer.isLoading,
    requestedUrl: state.UrlRewritesReducer.requestedUrl,
});

/** @namespace SwiatKsiazkiBasic/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    prepareRequest: (urlParam) => UrlRewritesDispatcher.prepareRequest({ urlParam }, dispatch),
    onSuccess: (response, urlParam) => UrlRewritesDispatcher.onSuccess(response, dispatch, { urlParam }),
    onError: (error, urlParam) => UrlRewritesDispatcher.onError(error, dispatch, { urlParam }),
});

/** @namespace SwiatKsiazkiBasic/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends DataContainer {
    static propTypes = {
        location: LocationType.isRequired,
        match: MatchType.isRequired,
        history: HistoryType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        requestedUrl: PropTypes.string,
        requestUrlRewrite: PropTypes.func.isRequired,
        urlRewrite: UrlRewriteType.isRequired,
    };

    static defaultProps = {
        requestedUrl: '',
    };

    static stateMapping = {
        category: TYPE_CATEGORY,
        product: TYPE_PRODUCT,
        page: TYPE_CMS_PAGE,
    };

    componentDidMount() {
        const { pathname, isLoading, requestedUrl } = this.props;

        this.initialUrl = pathname;

        if (isPreloadingRoute() && !requestedUrl) {
            document.addEventListener(PRELOAD_ROUTE_FINISHED, this.handlePreloadEvent.bind(this));
        } else if (this.getIsLoading() && !isLoading) {
            this.requestUrlRewrite();
        }
    }

    componentDidUpdate() {
        const { isLoading, requestedUrl } = this.props;

        /**
         * If the latest requested URL rewrite is not related
         * to the current location, and the URL rewrites are not loading
         * request new URL rewrite.
         */
        if (this.getIsLoading() && !isLoading && !isPreloadingRoute() && requestedUrl) {
            this.requestUrlRewrite();
        }

        /**
         * Make sure that PDP & PLP url don't have "/" in the end
         */
        this.redirectToCorrectUrl();
    }

    componentWillUnmount() {
        document.removeEventListener(PRELOAD_ROUTE_FINISHED, this.handlePreloadEvent.bind(this));
    }

    handlePreloadEvent() {
        const { pathname, onSuccess } = this.props;

        if (hasCacheValue(PRELOAD_KEYS.ROUTE)) {
            onSuccess({ route: getCacheValue(PRELOAD_KEYS.ROUTE) }, pathname);
        } else {
            this.componentDidMount();
        }
    }

    __construct(props) {
        super.__construct(props, 'UrlRewritesContainer');
    }

    redirectToCorrectUrl() {
        const { location, history } = this.props;

        const type = this.getType();

        if ([TYPE_CATEGORY, TYPE_PRODUCT].includes(type)) {
            if (location.pathname.endsWith('/')) {
                history.replace(location.pathname.slice(0, -1), history.state);
            }
        }
    }

    containerProps() {
        return {
            type: this.getType(),
            props: this.getProps(),
        };
    }

    getTypeSpecificProps() {
        const {
            urlRewrite: { page: { id, sku, dictionary_type, custom_root_category } = {} },
        } = this.props;

        const isLoading = this.getIsLoading();

        switch (this.getType()) {
            case TYPE_PRODUCT:
                /**
                 * In case we are not yet sure what product ID it is:
                 * - check if there is a hint in browser history
                 * - fallback to none
                 */
                if (isLoading) {
                    const product = history?.state?.state?.product;

                    if (product) {
                        const { sku: historySKU, id } = product;

                        return { productSKU: historySKU, id };
                    }

                    return {};
                }

                return { productSKU: sku, id };
            case TYPE_CMS_PAGE:
                if (isLoading) {
                    return { isOnlyPlaceholder: true };
                }

                return { pageIds: id };
            case TYPE_CATEGORY:
                /**
                 * In case we are not yet sure what category ID it is:
                 * - check if there is a hint in browser history
                 * - fallback to none
                 */
                if (isLoading) {
                    const category = history?.state?.state?.category;

                    if (category && category !== true) {
                        return { categoryIds: category, customRootCategory: custom_root_category };
                    }

                    return {};
                }

                return { categoryIds: id, customRootCategory: custom_root_category };
            case TYPE_DICTIONARY:
                if (isLoading) {
                    return {};
                }

                return { dictionaryId: id, dictionaryType: dictionary_type };
            case TYPE_NOTFOUND:
            default:
                return {};
        }
    }

    getIsLoading() {
        const { pathname, requestedUrl } = this.props;

        return pathname !== requestedUrl;
    }

    getProps() {
        const { location, match, history } = this.props;

        return {
            location,
            match,
            history,
            ...this.getTypeSpecificProps(),
        };
    }

    getFallbackType() {
        const { pathname, actionName: { type: initialType = '' } = {} } = window;

        if (this.initialUrl === pathname) {
            return initialType;
        }

        return '';
    }

    getType() {
        const {
            urlRewrite: { type, notFound },
        } = this.props;

        /**
         * If the URL rewrite is loading, prefer state-defined URL type,
         * else fallback to one defined in HTML document by PHP controller
         * (which is only valid for 1st load).
         */
        if (this.getIsLoading()) {
            const state = history?.state?.state || {};
            const typeKey = Object.keys(state).find((key) => UrlRewritesContainer.stateMapping[key]);

            if (typeKey) {
                return UrlRewritesContainer.stateMapping[typeKey];
            }

            /**
             * Otherwise fallback to other guessed types - from window i.e.
             */
            return this.getFallbackType();
        }

        if (notFound) {
            return TYPE_NOTFOUND;
        }

        if (type) {
            if (type === TYPE_CUSTOM) {
                return TYPE_NOTFOUND;
            }

            return type;
        }

        return '';
    }

    requestUrlRewrite() {
        const { pathname, prepareRequest, onSuccess, onError } = this.props;

        this.fetchData(
            prepareRequest(pathname),
            (result) => onSuccess(result, pathname),
            (error) => onError(error, pathname)
        );
    }

    render() {
        return <UrlRewrites {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
