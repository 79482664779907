import Html from 'Component/Html';
import Link from 'Component/Link';
import { Notification as SourceNotification } from 'SourceComponent/Notification/Notification.component';
import CSS from 'Util/CSS';

import {
    ANIMATION_DURATION,
    containsHTML,
    ERROR_NOTIFICATION_LIFETIME,
    ERROR_TYPE,
    NOTIFICATION_LIFETIME,
} from './Notification.config';

import './Notification.override.style';

/** @namespace SwiatKsiazkiBasic/Component/Notification/Component */
export class Notification extends SourceNotification {
    componentDidMount() {
        const {
            notification: { msgType },
            lifeTime,
        } = this.props;

        if (lifeTime !== -1) {
            // Make sure error notification stays a little longer
            if (msgType.toLowerCase() === ERROR_TYPE) {
                this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || ERROR_NOTIFICATION_LIFETIME);
            } else {
                this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || NOTIFICATION_LIFETIME);
            }
        }

        if (msgType.toLowerCase() === ERROR_TYPE) {
            CSS.setVariable(this.notification, 'animation-lifetime', `${lifeTime || ERROR_NOTIFICATION_LIFETIME}ms`);
        } else {
            CSS.setVariable(this.notification, 'animation-lifetime', `${lifeTime || NOTIFICATION_LIFETIME}ms`);
        }

        CSS.setVariable(this.notification, 'animation-duration', `${ANIMATION_DURATION}ms`);
    }

    renderTimer() {
        const { lifeTime } = this.props;

        if (lifeTime !== -1) {
            return <span block="Notification" elem="Timer" />;
        }

        return null;
    }

    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing',
        };

        return (
            <div block="Notification" mods={mods} ref={this.notification} id={id}>
                {this.renderTimer()}
                <p block="Notification" elem="Text">
                    {containsHTML(msgText) ? <Html content={msgText} /> : msgText}
                </p>
                <div block="Notification" elem="ActionButtons">
                    <button block="Notification" elem="CloseButton" onClick={this.hideNotification}>
                        {__('OK')}
                    </button>
                    {msgText?.indexOf(__('Product was added to cart!')) !== -1 && (
                        <Link to="/cart" onClick={this.hideNotification}>
                            {__('Go to cart')}
                        </Link>
                    )}
                </div>
                {this.renderDebug()}
            </div>
        );
    }
}

export default Notification;
