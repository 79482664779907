export const DEFAULT_MAIN_SETTINGS = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                arrows: false,
                dots: true,
            },
        },
        {
            breakpoint: 568,
            settings: {
                arrows: false,
                dots: true,
            },
        },
    ],
};

export const DEFAULT_MID_SETTINGS = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
            },
        },
        {
            breakpoint: 810,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
            },
        },
    ],
};

export const DEFAULT_BOTTOM_SETTINGS = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
        {
            breakpoint: 1279,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3.2,
                slidesToScroll: 1,
                arrows: false,
                swipeToSlide: false,
                lazyLoad: false,
                swipe: false,
                useCSS: false,
                useTransform: false,
            },
        },
    ],
};
