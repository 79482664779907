/** @namespace SwiatKsiazkiBasic/Component/CheckoutDeclarationPopup/Component/mapStateToProps */
import { connect } from 'react-redux';

import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import CheckoutDeclarationPopup from './CheckoutDeclarationPopup.component';

/** @namespace SwiatKsiazkiBasic/Component/CheckoutDeclarationPopup/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/CheckoutDeclarationPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeclarationPopup);
