import CmsPageQuery from 'Query/CmsPage.query';
import ProductListQuery from 'Query/ProductList.query';
import { Field, Fragment } from 'Util/Query';

/** @namespace SwiatKsiazkiBasic/Query/Route/Query */
export class RouteQuery {
    getQuery({ urlParam }) {
        return new Field('route')
            .setAlias('route')
            .addArgument('url', 'String!', urlParam)
            .addFieldList(this._getFields());
    }

    _getFields() {
        return [
            'type',
            this._getCmsPageFragment(),
            this._getProductFragment(),
            this._getCategoryTreeFragment(),
            this._getDictionaryPageFragment(),
        ];
    }

    _getCmsPageFragment() {
        return new Fragment('CmsPage').addFieldList(CmsPageQuery._getPageFields());
    }

    _getCategoryTreeFragment() {
        return new Fragment('CategoryTree').addFieldList([
            'id',
            'name',
            'seo_category_name_h1',
            'custom_root_category',
            'root_category_id',
            'meta_title',
            'meta_description',
            'is_anchor',
            'content_top',
            'content_bottom',
            new Field('description').setAlias('category_description'),
        ]);
    }

    _getDictionaryPageFragment() {
        return new Fragment('DictionaryPage').addFieldList(['dictionary_type', 'id']);
    }

    _getProductFragment() {
        return new Fragment('ProductInterface').addFieldList([
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'availability',
            'availability_status',
            'attribute_set_name',
            'canonical_url',
            'meta_title',
            'meta_keyword',
            'meta_description',
            ProductListQuery._getCategoriesField(),
            ProductListQuery._getMediaGalleryField(),
            ProductListQuery._getProductSmallField(),
            ProductListQuery._getProductImageField(),
            ProductListQuery._getDescriptionField(),
            ProductListQuery._getStockItemField(),
        ]);
    }
}

export default new RouteQuery();
