/* eslint-disable jsx-a11y/alt-text */
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import { IMAGE_LOADED, IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED } from 'Component/Image/Image.config';
import ImageLazyLoading from 'Component/ImageLazyLoading';
import { Image as SourceImage } from 'SourceComponent/Image/Image.component';
import { isCrawler } from 'Util/Browser';
import { toNumber } from 'Util/Data';

/** @namespace SwiatKsiazkiBasic/Component/Image/Component */
export class Image extends SourceImage {
    static propTypes = {
        ...SourceImage.propTypes,
        removeDefaultClasses: PropTypes.bool,
        useNativeLazyLoading: PropTypes.bool,
        lazyLoadingType: PropTypes.string,
        lazyLoadingMode: PropTypes.oneOf(['eager', 'lazy']),
        fetchPriority: PropTypes.oneOf(['high', 'low']),
        visibleByDefault: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceImage.defaultProps,
        removeDefaultClasses: false,
        useNativeLazyLoading: false,
        lazyLoadingType: 'default',
        lazyLoadingMode: 'lazy',
        fetchPriority: 'low',
        visibleByDefault: false,
    };

    getSrc() {
        const { src } = this.props;
        const { height, width } = this.getSizes();

        if (!src) {
            return '';
        }

        try {
            const url = new URL(src);

            const size = height > width ? height : width;

            if (size) {
                url.searchParams.set('height', size + 100);
                url.searchParams.set('width', size + 100);
            }

            return url.toString();
        } catch {
            return src;
        }
    }

    getSizes() {
        const { sizes } = this.props;

        const height = toNumber(sizes?.height);
        const width = toNumber(sizes?.width);

        return { height, width };
    }

    getImageProps() {
        const { alt, style, title, lazyLoadingMode, lazyLoadingType, useNativeLazyLoading, visibleByDefault } =
            this.props;

        const sizes = this.getSizes();
        const loading = visibleByDefault ? 'eager' : lazyLoadingMode;

        const height = sizes.height || undefined;
        const width = sizes.width || undefined;

        if (isCrawler() || useNativeLazyLoading) {
            return {
                src: this.getSrc(),
                alt,
                style,
                title,
                loading,
                onLoad: this.onLoad,
                onError: this.onError,
                height,
                width,
            };
        }

        return {
            src: this.getSrc(),
            alt,
            style,
            title,
            loading,
            lazyLoadingType,
            visibleByDefault,
            height,
            width,
        };
    }

    onImageChange() {
        const { src } = this.props;

        if (!src || src === 'false') {
            return this.setState({ imageStatus: IMAGE_NOT_SPECIFIED });
        }

        return super.onImageChange();
    }

    onError() {
        if (isCrawler()) {
            this.setState({ imageStatus: IMAGE_LOADED });
        } else {
            this.setState({ imageStatus: IMAGE_NOT_FOUND });
        }
    }

    renderPlainImage() {
        const { useNativeLazyLoading, removeDefaultClasses, className, visibleByDefault } = this.props;

        if (removeDefaultClasses) {
            return <img className="image-default-style" {...this.getImageProps()} />;
        }

        if (isCrawler() || useNativeLazyLoading) {
            return (
                <span className="lazy-load-image-background lazy-load-image-loaded">
                    <img block={className} {...this.getImageProps()} />
                </span>
            );
        }

        if (visibleByDefault) {
            return (
                <span className="lazy-load-image-background lazy-load-image-loaded">
                    <ImageLazyLoading {...this.getImageProps()} />
                </span>
            );
        }

        return <ImageLazyLoading {...this.getImageProps()} />;
    }

    renderStyledImage() {
        const { useNativeLazyLoading, visibleByDefault } = this.props;

        if (isCrawler() || useNativeLazyLoading) {
            return (
                <span className="lazy-load-image-background lazy-load-image-loaded">
                    <img {...this.getImageProps()} />
                </span>
            );
        }

        if (visibleByDefault) {
            return (
                <span className="lazy-load-image-background lazy-load-image-loaded">
                    <ImageLazyLoading {...this.getImageProps()} />
                </span>
            );
        }

        return <ImageLazyLoading {...this.getImageProps()} />;
    }

    renderPreload() {
        const { lazyLoadingMode, visibleByDefault, fetchPriority } = this.props;

        if (lazyLoadingMode === 'lazy' || !visibleByDefault) {
            return null;
        }

        const href = this.getSrc();

        return createPortal(
            <link key={href} rel="preload" as="image" href={href} fetchPriority={fetchPriority} />,
            document.head
        );
    }

    renderImage() {
        const { isPlaceholder } = this.props;
        const { imageStatus } = this.state;

        if (isPlaceholder) {
            return null;
        }

        const render = this.renderMap[imageStatus];

        if (!render) {
            return null;
        }

        return (
            <>
                {render()}
                {this.renderPreload()}
            </>
        );
    }
}

export default Image;
